import React from 'react'
import './Banner.css'
import {HiOutlineArrowNarrowRight , HiOutlineArrowNarrowLeft} from 'react-icons/hi'
import AnchorLink from 'react-anchor-link-smooth-scroll' 
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import {Link} from 'react-router-dom'

import sliderImage01 from '../../../assets/Basic-Needs/slider-2.jpg'
import sliderImage02 from '../../../assets/Basic-Needs/slider-3.jpg'
import sliderImage03 from '../../../assets/Basic-Needs/slider-1.jpg'



function Banner() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        nextArrow: <NextArrow  />,
        prevArrow: <PrevArrow />
    };

  return (
    <div>
        <Slider {...settings}>
            <SliderElement image={sliderImage01 }  first="Empowering Your" second="Drive" third="with NYK Trading" para="NYK Trading Your Pathway to Automotive Excellence Empowering Your Drive Through Premium Imports and Exports, Connecting Enthusiasts with Japan's Finest Vehicles and Spare Parts."/>
            <SliderElement image={sliderImage02}   first="We Offer Best Way To Find" second="Dream" third="vehicle" para="Discover your dream vehicle effortlessly with NYK Trading. Our streamlined platform provides the ultimate solution, uniting enthusiasts with their perfect match through expertise and dedication to quality."/>
            <SliderElement image={sliderImage03 }  first="Your Automotive" second="Journey" third="Begins Here" para="Welcome to the inception of your automotive journey. NYK Trading stands as your gateway to unparalleled vehicular experiences, uniting quality, expertise, and passion to fuel your path towards driving excellence."  />
        </Slider>
    </div>

  )
}

function NextArrow({onClick}){
    return(
        <div className='Arrow next-Arrow' onClick={onClick}>
            <HiOutlineArrowNarrowRight />
        </div>
    )
}

function PrevArrow({onClick}){
    return(
        <div className='Arrow prev-Arrow' onClick={onClick}>
            <HiOutlineArrowNarrowLeft />
        </div>
    )
}

function SliderElement({image , first ,second , third ,  para}){
    return(
        <div className='Banner' style={{
            backgroundImage: `linear-gradient(320deg, rgba(0, 0, 0, 0.7), rgb(0, 0, 0.1)), url('${image}')`
          }} >
            <div className='inner-container conatiner'>  
                <div className='content' >
                    <p className='sub-head' >Welcome To <br className='br' /> <span>NYK trading !</span></p>
                    <h3>{first} <span> {second} </span> {third}</h3>
                    <p  className='describtion'> {para}   </p>
                    <div className='button-groups'>
                        <button className='theme-btn' ><Link to='./About'><span>About More</span><HiOutlineArrowNarrowRight /></Link></button>
                        <button className='theme-btn-2' ><AnchorLink href='#whynyk'> <span>Learn More</span><HiOutlineArrowNarrowRight /></AnchorLink></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner