import React from 'react'
import './ShowFvrtVehicales.css'
import { useNykContext } from '../../../../../Context/NykContaxtProvider'
import { PiRoadHorizon } from 'react-icons/pi'
import { AiOutlineCalendar, AiOutlineStar } from 'react-icons/ai'
import { MdOutlineColorLens } from 'react-icons/md'

function ShowFvrtVehicales() {
  const {favrtVehicales} = useNykContext()

  console.log(favrtVehicales)

  return (
    <div className='show-fvrt-vehicale-container' >
        {
            favrtVehicales?.map((vehicale) => (
                <div className='na-product-container'>
                <div className='na-product-inner-container' >
                    <div className='na-img' >
                        <img src={vehicale?.Images[0]} alt='na01'/> 
                    </div>
                </div>
                <div className='items-deatils' >
                    <h4>{vehicale?.Brand} - {vehicale?.Model}</h4>
                    <div className='item-option' >
                        <div><PiRoadHorizon  /><span>{vehicale?.Mileage}</span></div>
                        <div><AiOutlineCalendar /><span>{vehicale?.Year}</span></div>
                        <div><MdOutlineColorLens /><span>{vehicale?.Color}</span></div>
                        <div><AiOutlineStar/><span>{vehicale?.Score}</span></div>
                    </div>
                    </div>
                    <div className='price-na' >
                        <h3>{vehicale?.Price}</h3>
                       
                    </div>
            </div>
            ))
        }

    </div>
  )
}

export default ShowFvrtVehicales