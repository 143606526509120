import React, { useState , useRef } from 'react'
import './MailRequest.css'
import imgMailReq from '../../../assets/Basic-Needs/slider-1.jpg'
import {BsCalendarMinus} from 'react-icons/bs'
import {MdOutlineColorLens} from 'react-icons/md'
import {TbWorldCheck , TbBrandToyota , TbRoad , TbMailUp } from 'react-icons/tb'
import {BiSolidCar} from 'react-icons/bi'
import {MdCreditScore} from 'react-icons/md'
import {FaMoneyBillAlt} from 'react-icons/fa'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useNykContext } from '../../../Context/NykContaxtProvider'
import emailjs from '@emailjs/browser';

function MailRequest() {
  const form = useRef()
  const {
    re1qMailBox,
    selectedCountry, 
    selectedBrand, 
    selectModel, 
    selectyear, 
    selectColur, 
    selectMilieage, 
    selectScore, 
    setNotiStatus, 
    setNotiError,
    setNotiContent,
    setReqMailBox,
    setPopupStatus,
    setSelectedYear,
    setSelectedColour,
    selectUPdatedPrice,
    setSelectedPrice
  } = useNykContext()  

  const [phone , setPhone]  = useState() 
  const [email , setEmail]  = useState() 
  const [name , setName]  = useState() 


  const sendEmail = (e) => {
    e.preventDefault();

    if(phone && email && name && selectedCountry && selectedBrand && selectModel && selectyear && selectColur && selectUPdatedPrice && selectMilieage && selectScore){
        emailjs.sendForm('service_7mnmhvh', 'template_jt66lsk', form.current, 'YdGJmTEEyMlaSQgNJ')
        .then((result) => {
            if(result.text === "OK"){       
                setNotiContent("Email Sent Successfully")     
                setNotiStatus(true);
                setNotiError(true)
                setTimeout(() =>{
                      setNotiStatus(false);
                      setNotiError(false)
                      setReqMailBox(false)
                      setPopupStatus(false)
                },4000)
                setSelectedColour("")
                setSelectedPrice("")
                setSelectedYear("")
            }else{
                setNotiContent("Email not sent")
                setNotiStatus(true);
                setNotiError(false)
            }
        }, (error) => {
            console.log(error.text);
            setNotiStatus(true);
            setNotiError(false)
            setTimeout(() =>{
                  setNotiStatus(false);
            },4000)
        });
    }else{
        setNotiContent("Please Fill All The Feild")
        setNotiStatus(true);
        setNotiError(false)
        setTimeout(() =>{
              setNotiStatus(false);
        },4000)
    }
  };

  return (
    <div className={`mail-request-popup ${re1qMailBox && 'mail-request-open'}`} >
        <div className='mail-request-popup-inner-conatiner' >
            <div className='mail-req-flex mail-req-image' >
                <img src={imgMailReq} alt="imgMailReq" />
            </div>
            <div className='mail-req-flex mail-req-content'>
                <div className='mail-req-content-inner' >
                    <h3>Request Your <span>Dream</span> Car</h3>

                    <div className='mr-options' >
                        <div className='mr-eash-option'>
                            <TbWorldCheck />
                            <span>{selectedCountry}</span>
                        </div>
                        <div className='mr-eash-option'>
                            <TbBrandToyota />
                            <span>{selectedBrand}</span>
                        </div>
                        <div className='mr-eash-option'>
                            <BiSolidCar />
                            <span>{selectModel}</span>
                        </div>
                        <div className='mr-eash-option'>
                            <TbRoad />
                            <span>{selectMilieage}</span>
                        </div>
                        <div className='mr-eash-option'>
                            <BsCalendarMinus />
                            <span>{selectyear}</span>
                        </div>
                        <div className='mr-eash-option'>
                            <MdOutlineColorLens />
                            <span>{selectColur}</span>
                        </div>
                        <div className='mr-eash-option'>
                            <FaMoneyBillAlt/>
                            <span>{selectUPdatedPrice}</span>
                        </div>
                        <div className='mr-eash-option'>
                            <MdCreditScore />
                            <span>{selectScore}</span>
                        </div>
                    </div>

                    <form className='mr-form' ref={form} onSubmit={sendEmail}>

                        <div className='name-email-mr' >
                            <div className='each-mr-feild first-mr mr-name' >
                                <p>Name </p>
                                <div className='mr-custom-feild' >
                                    <input onChange={(e) => setName(e.target.value)} type='text' placeholder='Enter You Name' name='from_name' />
                                </div>
                            </div>
                            <div className='each-mr-feild mr-email' >
                                <p>Email </p>
                                <div className='mr-custom-feild' >
                                    <input onChange={(e) => setEmail(e.target.value)} inputMode='email' type='email' placeholder='Enter You Email' name='from_email'  />
                                </div>
                            </div>
                        </div>
                        
                        <div className='each-mr-feild contact-mr' >
                            <p>Phone Number </p>
                            <div className='mr-custom-feild' >
                                <PhoneInput
                                    country={'jp'}
                                    value={phone}
                                    onChange={phone => setPhone(phone)}
                                    placeholder='Enter Your Phone No '
                                    inputProps={{
                                        name :'contact_number'
                                    }}
                                />
                            </div>
                        </div>

                        <input type='text' value={selectedCountry} name='country' hidden/>
                        <input type='text' value={selectedBrand} name='brand' hidden/>
                        <input type='text' value={selectModel} name='model' hidden/>
                        <input type='text' value={selectMilieage} name='km' hidden/>
                        <input type='text' value={selectyear} name='year' hidden/>
                        <input type='text' value={selectColur} name='color' hidden/>
                        <input type='text' value={selectUPdatedPrice}  name='price' hidden/>
                        <input type='text' value={selectScore} name='score' hidden/>
                        
                        <button className='theme-btn mr-request-btn request-btn'><TbMailUp /><span>Send Message</span></button>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default MailRequest