import React , {useEffect, useRef , useState} from 'react'
import './Whyus.css'
import wcuImage from '../../../assets/Basic-Needs/wcu.png' 
import {HiUserGroup} from 'react-icons/hi'
import {AiOutlineSetting} from 'react-icons/ai'
import {MdOutlinePriceChange} from 'react-icons/md'
import {BsCarFront} from 'react-icons/bs'
import {LuGraduationCap} from 'react-icons/lu'
import Plx from "react-plx";


function Whyus() {
    const [height , setHeight] = useState(0)
    const myRef = useRef()

    useEffect(() => {
        if(myRef){
            setHeight(myRef.current.offsetTop)
        }
    },[])

    const parallaxData = [
        {
            start: height  - 800,
            end:height + 800,
            easing : "ease",
            duration:1,
            properties: [
              {
                startValue : -500,
                endValue: 0,
                property: "translateX",
              },
              {
                startValue : 0.2,
                endValue: 1,
                property: "scale",
              },
              {
                startValue : 0,
                endValue: 1,
                property: "opacity",
              },
            ],
          },
    ];
    


  return (
    <div className='full-wcu-container' ref={myRef} id="whynyk">
        <div className='conatiner wcu-container' >
            <div className='wcu-inner-container'>
                <div className='wcu-left' >
                    <p data-aos="fade-down" data-aos-duration="800" className='sub-tittle' ><HiUserGroup /><span>Why Choose Us</span></p>
                    <h3 data-aos="fade-left" data-aos-duration="800" >We are dedicated <span>to provide</span> quality service.</h3>
                    <p data-aos="fade-right" data-aos-duration="800" className='descripbtion'>Committed to Excellence. Quality defines NYK Trading. Trusted partners in importing/exporting vehicles and parts, ensuring transparency, satisfaction, and lasting relationships</p>
                    <Plx className="MyAwesomeParallax" parallaxData={parallaxData}>
                        <img src={wcuImage } alt='wcuImage' />
                    </Plx>
                </div>
                <div className='wcu-right' >

                    <div className='wcu-right-inner-container' >
                        <div className='wcu-why-card left-card' data-aos="fade-up" data-aos-duration="800"  >
                            <h2>01</h2>
                            <div className='wcu-card-icon' >
                                <BsCarFront />
                            </div>
                            <h3>Best Quality Vehicle</h3>
                            <p>Explore Japan's finest vehicles with NYK Trading. Our commitment to quality and transparency ensures exceptional driving experiences through trusted transactions</p>
                        </div>
                        <div className='wcu-why-card right-card' data-aos="fade-up" data-aos-duration="800" >
                            <h2>02</h2>
                            <div className='wcu-card-icon' >
                                <LuGraduationCap />
                            </div>
                            <h3>Seamless Service</h3>
                            <p>NYK Trading ensures a seamless journey from sourcing to delivery, bringing Japan's finest vehicles to your doorstep effortlessly.</p>
                        </div>
                        <div className='wcu-why-card left-card' data-aos="fade-up" data-aos-duration="800" >
                            <h2>03</h2>
                            <div className='wcu-card-icon' >
                                <MdOutlinePriceChange />
                            </div>
                            <h3>Reasonable Price</h3>
                            <p>Experience Japan's automotive treasures at reasonable prices through NYK Trading. Unveil quality and value in every transaction."</p>
                        </div>
                        <div className='wcu-why-card right-card' data-aos="fade-up" data-aos-duration="800" >
                            <h2>04</h2>
                            <div className='wcu-card-icon' >
                                <AiOutlineSetting />
                            </div>
                            <h3>Popular Brands</h3>
                            <p>NYK Trading brings renowned automotive brands from Japan's hub to you. Quality, authenticity, and excellence in every choice.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Whyus