import React, { useState } from 'react'
import './RequestCarBox.css'
import { TbBrandToyota,  TbRoad, TbWorldCheck } from 'react-icons/tb'
import { BiLogoTelegram, BiSolidCar } from 'react-icons/bi'
import { BsCalendarMinus } from 'react-icons/bs'
import { MdCreditScore, MdOutlineColorLens } from 'react-icons/md'
import { FaMoneyBillAlt } from 'react-icons/fa'
import { RxCross2 } from 'react-icons/rx'
import PhoneInput from 'react-phone-input-2'
import { } from 'react-icons/bi'
import { useNykContext } from '../../../Context/NykContaxtProvider'
import { db } from '../../../firebase-config'
import { Timestamp, addDoc, collection } from 'firebase/firestore'

function RequestCarBox() {
  const [phone , setPhone]  = useState() 
  const [email , setEmail]  = useState() 
  const [name , setName]  = useState() 

  const {messageSendStatus , setMessageSendStatus , messageSend , setNotiContent  , setNotiStatus , setNotiError} = useNykContext()

  const requestCollection = collection(db , 'requests')  

  const sendMessage = (e) => {
    e.preventDefault()
    
    if(name && email && phone){
        addDoc(requestCollection , {
            Image : messageSend?.Images[0],
            Country : messageSend?.Country,
            Brand : messageSend?.Brand,
            Model : messageSend?.Model,
            Color : messageSend?.Color,
            Mileage : messageSend?.Mileage,
            Score : messageSend?.Score,
            Year : messageSend?.Year,
            Price : messageSend?.Price,
            Name : name,
            Email : email,
            Phone : phone,
            creatDate:Timestamp.now().toDate()
        }).then(() => {
            setNotiContent("Request Has Been Sent") 
            setNotiStatus(true)
            setNotiError(false)
            setTimeout(() =>{
                setNotiStatus(false);
                setNotiError(false);
                setPhone("")
                setEmail("")
                setName("")
            },4000)
            setMessageSendStatus(false)
            
        }).catch(() => {
            setNotiContent("Something Went Wrong Please Try Again Later") 
            setNotiStatus(true)
            setNotiError(true)
            setTimeout(() =>{
                setNotiStatus(false);
                setNotiError(false)
            },4000)
        })
    }else{
        setNotiContent("Please Fill All The Feild")
        setNotiStatus(true);
        setNotiError(false)
        setTimeout(() =>{
              setNotiStatus(false);
        },4000)
    }
    
  }

  const handleMessagebox = () => {
    setMessageSendStatus(false)
  }

  return (
    <div className={`request-box-container ${messageSendStatus  && 'request-box-container-open'}`} >
        <div onClick={handleMessagebox} className='cancel-image-slider' >
            <RxCross2 />
        </div>
        <div className='request-box' >
            <div className='request-box-inner' >
                <h3>Request Your <span>Dream</span> Car</h3>

                <div className='mr-options mr-optins-request-box' >
                    <div className='mr-eash-option'>
                        <TbWorldCheck />
                        <span>{messageSend?.Country}</span>
                    </div>
                    <div className='mr-eash-option'>
                        <TbBrandToyota />
                        <span>{messageSend?.Brand}</span>
                    </div>
                    <div className='mr-eash-option'>
                        <BiSolidCar />
                        <span>{messageSend?.Model}</span>
                    </div>
                    <div className='mr-eash-option'>
                        <TbRoad />
                        <span>{messageSend?.Mileage}</span>
                    </div>
                    <div className='mr-eash-option'>
                        <BsCalendarMinus />
                        <span>{messageSend?.Year}</span>
                    </div>
                    <div className='mr-eash-option'>
                        <MdOutlineColorLens />
                        <span>{messageSend?.Color}</span>
                    </div>
                    <div className='mr-eash-option'>
                        <FaMoneyBillAlt/>
                        <span>{messageSend?.Price}</span>
                    </div>
                    <div className='mr-eash-option'>
                        <MdCreditScore />
                        <span>{messageSend?.Score}</span>
                    </div>
                </div>
                <form className='mr-form' onSubmit={(e) => sendMessage(e)}>
                    <div className='name-email-mr' >
                        <div className='each-mr-feild first-mr mr-name' >
                            <p>Name </p>
                            <div className='mr-custom-feild' >
                                <input onChange={(e) => setName(e.target.value)} type='text' placeholder='Enter You Name' name='from_name' />
                            </div>
                        </div>
                        <div className='each-mr-feild mr-email' >
                            <p>Email </p>
                            <div className='mr-custom-feild' >
                                <input onChange={(e) => setEmail(e.target.value)} inputMode='email' type='email' placeholder='Enter You Email' name='from_email'  />
                            </div>
                        </div>
                    </div>
                    <div className='each-mr-feild contact-mr' >
                            <p>Phone Number </p>
                            <div className='mr-custom-feild' >
                                <PhoneInput
                                    country={'jp'}
                                    value={phone}
                                    onChange={phone => setPhone(phone)}
                                    placeholder='Enter Your Phone No '
                                    inputProps={{
                                        name :'contact_number'
                                    }}
                                />
                            </div>
                    </div>
                    <button className='theme-btn mr-request-btn request-btn'><BiLogoTelegram /><span>Send Message</span></button>
                </form>
            </div>

        </div>
       
    </div>
  )
}

export default RequestCarBox