import React  from 'react'
import './VehicaleAddPage.css'
import {motion} from 'framer-motion'
import SideBar from '../components/SideBar/SideBar';
import Nav from '../components/Nav/Nav';
import { useNykContext } from '../../../Context/NykContaxtProvider';
import PopupBlack from '../components/PopupBlack/PopupBlack';
import {BiArrowBack, BiSolidAddToQueue} from 'react-icons/bi'
import VehicaleAddForm from './components/VehicaleAddForm/VehicaleAddForm';
import Notification from '../../HomePage/Notification/Notification';
import { Link } from 'react-router-dom';

function VehicaleAddPage() {
  const {adSideBar  ,  setPopupStatus , adSideBarStatusMobile} = useNykContext()


  const handleSidebar = () => {
    setPopupStatus(false)
    adSideBarStatusMobile(false)
  }

  return (
        <motion.div animate={{opacity:1 }} exit={{opacity:0}} initial={{opacity:0 }} transition={{duration:0.5}}>
           <SideBar page='Vehicales'   dashbord='../Admin/Adminpanel' vehicale='../Admin/Adminpanel/Vehicale/Add-Vehicale' request = '../Admin/Adminpanel/Requested-Vehicale' favourite='../Admin/Adminpanel/Favourite-Vehicale'/>
           <div className={`main-admin-home vehicale-manage ${adSideBar  && 'main-admin-home-full'}`} >
                <Nav />
                <div className='main-admin-innner' >
                    <div className='main-admin-head'>
                      <h1><BiSolidAddToQueue /><span>Add New Vehicale</span></h1>
                      <Link to='../Admin/Adminpanel/Vehicale'><BiArrowBack /><span>Back</span></Link>
                    </div>
                    <VehicaleAddForm />
                </div>
           </div>
           <PopupBlack handleSidebar={handleSidebar} />
           <Notification />
        </motion.div>
  )
}

export default VehicaleAddPage