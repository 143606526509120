import React, { useEffect, useState } from 'react'

import logo from '../../../assets/Logo/NYK-logo-01.png'

import {BiHome , BiMenuAltLeft} from 'react-icons/bi'
import {RiContactsLine} from 'react-icons/ri'
import {HiUserGroup} from 'react-icons/hi'
import {TbSettingsStar} from 'react-icons/tb' 
import {PiNotePencilBold} from 'react-icons/pi'
import {BiLogoTelegram} from 'react-icons/bi'
import {Link} from 'react-router-dom'

import AnchorLink from 'react-anchor-link-smooth-scroll' 
import { useNykContext } from '../../../Context/NykContaxtProvider'

function Nav() {
  const [navStatus , setNavStatus] = useState()
  const {setPopupStatus, setSiderbarStatus} = useNykContext()


  const transtionNavBar = () => {
    if(window.scrollY > 300) {
      setNavStatus(true)
    }else{
      setNavStatus(false)
    }
  }  

  const handleSideBar = () => {
    setSiderbarStatus(true)
    setPopupStatus(true)
  }

  useEffect(() => {
    window.addEventListener("scroll" , transtionNavBar)

    return () => window.removeEventListener("scroll" , transtionNavBar)
  }, [])

  return (
    <nav className={`${navStatus && 'nav-top'}`}>
        <div className='nav-container conatiner' >
            <div className='left-nav-element' >
              <img src={logo } alt='logo-nyk' /> 
            </div>
            <div className='middle-nav-element' >
              <ul>
                  <li><Link to='../'><BiHome /><span>Home</span></Link></li>
                  <li><Link to='../About'><HiUserGroup/><span>About</span></Link></li>
                  <li className='active'  ><Link to='./' ><RiContactsLine /><span>Contact</span></Link></li>
                  <li><Link to='../Services' ><TbSettingsStar /><span>Services</span></Link></li>
                  <li><Link to='../Request-Vehicale' ><BiLogoTelegram /><span>Request</span></Link></li>
              </ul>
            </div>
            <div className='right-nav-element' >
                <button className='theme-btn nav-btn'><AnchorLink href='#contact' ><PiNotePencilBold  /><span>Get A Quote</span></AnchorLink></button>
                <BiMenuAltLeft onClick={handleSideBar} className='menu' />
            </div>
        </div>
    </nav>
  )
}

export default Nav