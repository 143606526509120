import React from 'react'
import './ViewdVehicale.css'
import { TbBrandToyota, TbRoad, TbWorldCheck } from 'react-icons/tb'
import { BiImage,BiSolidCar, BiTrashAlt } from 'react-icons/bi'
import { BsCalendarMinus } from 'react-icons/bs'
import { MdCreditScore, MdOutlineColorLens } from 'react-icons/md'
import { FaMoneyBillAlt } from 'react-icons/fa'
import { RxCross2, RxUpdate } from 'react-icons/rx'
import { useNykContext } from '../../../../Context/NykContaxtProvider'
import {useNavigate} from 'react-router-dom'

function ViewdVehicale() {
  const {viewdVehicaleStatus , setViewdVehicaleStatus , viedVehicale , setDeleteConfomrationStatus} = useNykContext()
  const navigate = useNavigate()

  const handleViewd = () => {
    setViewdVehicaleStatus(false)
  }

  const handleUpdate = () => {
    setViewdVehicaleStatus(false)
    navigate(`../Admin/Adminpanel/Vehicale/Update-Vehicale/${viedVehicale?.id}`)
  }

  const handleDeleteBoxOpen = () => {
    setViewdVehicaleStatus(false)
    setTimeout(() => {
        setDeleteConfomrationStatus(true)
    },200)
  }

  return (
    <div className={`viewd-vehicale-full-black ${viewdVehicaleStatus  && 'viewd-vehicale-full-black-open'}`} >
        <div onClick={handleViewd} className='cancel-viewd-vehicale' >
                <RxCross2 />
        </div>
        <div className={`container-viewd-vehicale `} >
            <div className='inner-container-viewd-vehicale' >
                <h3><span>{viedVehicale?.Brand}</span><span> | </span><span>{viedVehicale?.Model}</span></h3>
                <div className='mr-options viewd-vehicale-option' >
                    <div className='mr-eash-option'>
                        <TbWorldCheck />
                        <span>{viedVehicale?.Country}</span>
                    </div>
                    <div className='mr-eash-option'>
                        <TbBrandToyota />
                        <span>{viedVehicale?.Brand}</span>
                    </div>
                    <div className='mr-eash-option'>
                        <BiSolidCar />
                        <span>{viedVehicale?.Model}</span>
                    </div>
                    <div className='mr-eash-option'>
                        <TbRoad />
                        <span>{viedVehicale?.Mileage}</span>
                    </div>
                    <div className='mr-eash-option'>
                        <BsCalendarMinus />
                        <span>{viedVehicale?.Year}</span>
                    </div>
                    <div className='mr-eash-option'>
                        <MdOutlineColorLens />
                        <span>{viedVehicale?.Color}</span>
                    </div>
                    <div className='mr-eash-option'>
                        <FaMoneyBillAlt/>
                        <span>{viedVehicale?.Price}</span>
                    </div>
                <div className='mr-eash-option'>
                        <MdCreditScore />
                        <span>{viedVehicale?.Score}</span>
                    </div>
                </div>
                <div className='image-container-viewd-vehicale'>
                    <p><BiImage /><span>All Vehicale Images</span></p>
                    <div className='image-inner-container-viewd-vehicale' >
                        {
                            viedVehicale?.Images?.map((img , index) => (
                                <div key={index} className='each-viewed-vehicale' >
                                    <img src={img} alt='viewd-vehicale' />
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className='button-continaer-viewd-vehicale' >
                    <button onClick={handleDeleteBoxOpen} className='theme-btn viewd-btn Delete-btn'><BiTrashAlt /><span>Delete Vehicale</span></button>
                    <button onClick={handleUpdate} className='theme-btn viewd-btn Update-btn'><RxUpdate /><span>Update Vehicale</span></button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ViewdVehicale