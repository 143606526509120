import React from 'react'
import './DirectorSpeech.css'
import nazreen from '../../../assets/Team/NAZ.jpg'
import direcor_sign from '../../../assets/Basic-Needs/director-sign.png'


const DirectorSpeech = () => {
  return (
    <div className='conatiner direcrot-container' id="director-speech" >
        <div className='director-inner-container' >
            <div className='director-image' >
                <img src={nazreen} alt='director-nyk' />
            </div>
            <div className='director-speech' >
                <h1>Director Speech - <span>Nazreen</span></h1>
                <div className='desccription' >
                    <p>Welcome to NYK trading, your trusted partner in the world of automotive imports and exports. With a passion for quality and a commitment to excellence, we specialize in sourcing, importing, and exporting vehicles and spare parts from the automotive hub of Japan.</p>
                    <p>Our journey began with a simple goal: to bridge the gap between automotive enthusiasts and Japan's rich automotive market. With years of experience in the industry, we have cultivated strong relationships with reputable suppliers, ensuring that we bring you the finest selection of vehicles and genuine spare parts that meet your every need.</p>
                    <p>At NYK trading, we understand that each vehicle and spare part tells a unique story. That's why we take great care in curating a diverse range of options that cater to different preferences and requirements. Whether you're looking for a rare classic, a high-performance machine, or reliable spare parts, we have you covered.</p>
                </div>
            </div>
        </div>
        <div className='rest-op-speech' >
            <p className='speech' >What sets us apart is our unwavering dedication to transparency, quality, and customer satisfaction. Our team of experts meticulously inspects and verifies each vehicle and spare part, providing you with accurate information to make informed decisions. We pride ourselves on delivering on our promises, ensuring that every import and export process is seamless and hassle-free. As automotive enthusiasts ourselves, we share your passion for the open road and the thrill of driving. Our commitment goes beyond just transactions – we're here to build lasting relationships with fellow enthusiasts who share our love for all things automotive. Thank you for choosing NYK trading as your partner in bringing the best of Japan's automotive industry to your doorstep. Join us in our journey of exploration, discovery, and exceptional driving experiences.</p>
            <div className='signatute' >
                <img src={direcor_sign } alt='director-sign' />
                <p>Director Of NYK Trading PVT.LTD - <span>Nazreen</span></p>
            </div>
        </div>
    </div>
  )
}

export default DirectorSpeech