import React from 'react'
import './DeleteConformation.css'
import {MdOutlineCancel} from 'react-icons/md'
import { BiTrashAlt } from 'react-icons/bi'
import {  RxCross2} from 'react-icons/rx'
import { useNykContext } from '../../../../Context/NykContaxtProvider'
import {  doc, deleteDoc} from 'firebase/firestore'
import { db } from '../../../../firebase-config'

function DeleteConformation() {
  const {delteconfomationStatus , setDeleteConfomrationStatus , viedVehicale  , setNotiContent , setNotiStatus , setNotiError} = useNykContext()

  const handleCloseDeleteBox = () => {
    setDeleteConfomrationStatus(false)
  }

  const handleDleteProduct = async () => {
    const singleVehicale = doc(db ,'vehicales' , viedVehicale?.id )
    await deleteDoc(singleVehicale).then(() => {
        setNotiContent("Vehicale Deleted Successfully") 
        setNotiStatus(true)
        setNotiError(false)
        setTimeout(() =>{
            setNotiStatus(false);
            setNotiError(false)
        },4000)
        setDeleteConfomrationStatus(false)
    })
  }

  return (
    <div className={`delete-conformation-full-container ${delteconfomationStatus && 'delete-conformation-full-container-open'}`}>
        <div className='delete-conformation-container' >
            <div className='delete-conformation-inner-container' >
                <MdOutlineCancel className='delte-confomation-svg'/>
                <p>Do you really want to delete this <span>{viedVehicale?.Brand} </span><span>{viedVehicale?.Model}</span> car ? Once if you delete this vehicale you can't recover it </p>
                <div className='button-continaer-viewd-vehicale delete-conformation-button-continer' >
                    <button onClick={handleDleteProduct} className='theme-btn viewd-btn Delete-btn'><BiTrashAlt /><span>Delete</span></button>
                    <button onClick={handleCloseDeleteBox} className='theme-btn viewd-btn Update-btn'><RxCross2/><span>Cancel</span></button>
                </div>
            </div>  
        </div>
    </div>
  )
}

export default DeleteConformation