import React  from 'react'
import './ShowAllRequestedvehicale.css'
import { DataGrid } from '@mui/x-data-grid'
import {AiFillEye} from 'react-icons/ai'
import { useNykContext } from '../../../../../Context/NykContaxtProvider'

const columns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'Image', headerName: 'Image', flex: 1, heigh:120,minWidth: 100,
      sortable : false,
      renderCell : (params) => {

        return (
            <div  className='image-cell data-table-cell' >
                <img src={params.row.Image} alt='cell-img' />
            </div>
        )
      }
    },
    
    { field: 'Name', headerName: 'Name', flex: 1  ,   minWidth: 50,},
    { field: 'Phone', headerName: 'Phone', flex: 1 ,    minWidth: 150,  },
    { field: 'Email', headerName: 'Email', flex: 1 ,    minWidth: 200,  },
    { field: 'Country', headerName: 'Country', flex: 1 ,minWidth: 50, },
    { field: 'Brand', headerName: ' Brand',flex: 1 ,  minWidth: 50, },
    { field: 'Model', headerName: ' Model',flex: 1 ,  minWidth: 50, },
  ];


  

function ShowAllRequestedvehicale() {
  const {clinetRequest , setClinetRequestBoxStatus , setClinetRequestBox } = useNykContext()
  
  const actionColumn = [
    {
        feild: 'View', 
        headerName: 'View', 
        flex: 1,
        sortable : false,
        minWidth: 100,
        renderCell : (params) => {
            const handleView = () => {
              const fillterdRequest= clinetRequest.filter((item) => item.id === params.row.id)
              setClinetRequestBox(fillterdRequest[0])
              setClinetRequestBoxStatus (true)
            }

            return(
                <div className='button-cell' onClick={handleView} >
                         <button className='cell-buttton-view' ><AiFillEye /><span>View</span></button>
                </div>
            )
        }
    }
  ]

  
  return (
    <div className='show-case-vehicle-container'>
       <div className='show-case-vehicle-container-inner'>
          <h3>Requests From Clients</h3>
          <div className='data-table'>
                <DataGrid
                    rows={clinetRequest}
                    rowHeight={85}
                    columns={columns.concat(actionColumn)}
                    initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                    }}
                    pageSizeOptions={[5, 10]}
                    columnVisibilityModel={{
                        id: false,
                    }}
                />
             </div>
        </div>
    </div>
  )
}

export default ShowAllRequestedvehicale