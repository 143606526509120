import React, { useState , useRef } from 'react'
import './VehicaleAddForm.css'
import {country ,  Brand , Car_model} from '../../../../../NykDb'
import {BiSolidChevronDown} from 'react-icons/bi'
import {BsSearch , BsCalendarMinus , BsCurrencyYen , BsImages} from 'react-icons/bs'
import {FiUpload} from 'react-icons/fi'
import { useNykContext } from '../../../../../Context/NykContaxtProvider'
import {MdDeleteOutline} from 'react-icons/md'
import { db, storage } from '../../../../../firebase-config'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { TailSpin } from  'react-loader-spinner'
import { Timestamp, addDoc, collection } from 'firebase/firestore'

function VehicaleAddForm() {
    const [selectedCountry , setSelectedCountry]  = useState(country[0]);
    const [selectedBrand , setSelectedBrand]  = useState(Brand[selectedCountry][0]);
    const [selectModel , setSelectedModel]  = useState(Car_model[selectedBrand][0]);
    const [selectyear , setSelectedYear]  = useState("");
    const [selectColur , setSelectedColour]  = useState("Pearl");
    const [selectPrice , setSelectedPrice]  = useState("");
    const [selectUPdatedPrice , setSelectedUPdatedPrice]  = useState("")
    const [selectScore , setSelectedScore]  = useState("All Score")
    const [selectMilieage , setSelectedMilieage]  = useState("All Km")
    const [selectedImagesList , setSelectedImagesList]  = useState([])
    const [fillterModel , setFillterModel] = useState(Car_model[selectedBrand])
    const [imageUploadingStatus  , setImageUploadingStatus] = useState(false)

    const photoRef = useRef()
    const [clicked , setClicked]  = useState("");

    const {setNotiStatus, setNotiError, setNotiContent }  = useNykContext() 

    const vehicaleCollection = collection(db , 'vehicales')  

    const handleClicked = (selectedItem) => {
        setClicked(selectedItem)
        if(selectedItem === clicked ){
        setClicked("")
        }
    }

    const handleSelectedContry  = (itemSeelcted) => {
        setSelectedBrand(Brand[itemSeelcted][0])
        setSelectedCountry(itemSeelcted)
        

        const handleBrand = Brand[itemSeelcted][0]
        setFillterModel(Car_model[handleBrand])
        setSelectedModel(Car_model[handleBrand][0])
    }

    const handleSelectedBrand  = (itemSeelcted) => {
        setSelectedModel(Car_model[itemSeelcted][0])
        setFillterModel(Car_model[itemSeelcted])
        setSelectedBrand(itemSeelcted)
    }

    const handleSelectYear  = (e) => {
        setSelectedYear(e.target.value)
    }
    
    const handleSelectColour  = (itemSeelcted) => {
        setSelectedColour(itemSeelcted)
    }
    
    const handleSelectPrice  = (e) => {
        setSelectedPrice(e.target.value)

        const price = e.target.value
        const parts = price.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        setSelectedUPdatedPrice(parts[0] + ' ¥')
    }
    
    const handleSelectScore  = (itemSeelcted) => {
        setSelectedScore(itemSeelcted)
    }
    
    const handleSelectModel = (itemSeelcted) => {
        setSelectedModel(itemSeelcted)
        setClicked("")
    
    }
    
    const handleSelectMilieage = (itemSeelcted) => {
        setSelectedMilieage(itemSeelcted)
    }
    
    const handleFillterModel = (e) => {
        const filltersmodels= Car_model[selectedBrand].filter((model) => model.toLowerCase().includes(e.target.value.toLowerCase()) )
        setFillterModel(filltersmodels)
    }

    const handleFileOpen = () => {
        photoRef.current.click()
    }

    const removeImage = (imageToRemove) => {
        setSelectedImagesList(prevList => prevList.filter(image => image !== imageToRemove));
        setNotiContent("Image Has Been Removed") 
        setNotiStatus(true)
        setNotiError(true)
        setTimeout(() =>{
            setNotiStatus(false);
            setNotiError(false)
        },4000)
    };
    

    const handleUploadVehicale = () => {

        if(selectColur && selectPrice && selectyear && (selectedImagesList.length >= 5) ){

            addDoc(vehicaleCollection , {
                Images : selectedImagesList,
                Country : selectedCountry,
                Brand : selectedBrand,
                Model : selectModel,
                Color : selectColur,
                Mileage : selectMilieage,
                Score : selectScore,
                Year : selectyear,
                Price : selectUPdatedPrice,
                ISfavourite : false,
                creatDate:Timestamp.now().toDate()
            }).then(() => {
                setNotiContent("Vehicale Uploaded Successfully") 
                setNotiStatus(true)
                setNotiError(false)
                setTimeout(() =>{
                    setNotiStatus(false);
                    setNotiError(false)

                    setSelectedCountry(country[0])
                    setSelectedBrand(Brand[country[0]][0])
                    setSelectedModel(Car_model[Brand[country[0]][0]][0])
                    setSelectedYear("")
                    setSelectedColour("Pearl")
                    setSelectedPrice("")
                    setSelectedUPdatedPrice("")
                    setSelectedScore("All Score")
                    setSelectedMilieage("All Km")
                    setSelectedImagesList([])
                    setFillterModel(Car_model[selectedBrand])
                },4000)
                
            }).catch(() => {
                setNotiContent("Something Went Wrong Please Try Again Later") 
                setNotiStatus(true)
                setNotiError(true)
                setTimeout(() =>{
                    setNotiStatus(false);
                    setNotiError(false)
                },4000)
            })

        }else{
            if(selectedImagesList.length < 5){
                console.log(selectedImagesList.length)
                setNotiContent("Please Select Atleat 5 Image") 
                setNotiStatus(true)
                setNotiError(true)
                setTimeout(() =>{
                    setNotiStatus(false);
                    setNotiError(false)
                },4000)
            }
            if(!selectyear){
                setNotiContent("Please Select Year")
                setNotiStatus(true)
                setNotiError(true)
                setTimeout(() =>{
                    setNotiStatus(false);
                    setNotiError(false)
                },4000)
            }
            if(!selectColur){
                setNotiContent("Please Select Color")
                setNotiStatus(true)
                setNotiError(true)
                setTimeout(() =>{
                    setNotiStatus(false);
                    setNotiError(false)
                },4000)
            }
            if(!selectPrice){
                setNotiContent("Please Select Price") 
                setNotiStatus(true)
                setNotiError(true)
                setTimeout(() =>{
                    setNotiStatus(false);
                    setNotiError(false)
                },4000)
            }
            
        }
    }

    const handleImageUpload = (e) => {
        const imageFile = e.target.files[0]
        setImageUploadingStatus(true)
        const stoarageRef = ref(storage , `/Vehicale/${Date.now()}${imageFile.name}` )
        const uploadImage = uploadBytesResumable(stoarageRef , imageFile)
        uploadImage.on("state_changed" , () => {

            },(error) =>{
                console.log(error.message)
            },() => {
                getDownloadURL(uploadImage.snapshot.ref).then((url) => {
                    if(url){
                        setImageUploadingStatus(false)
                        setNotiContent("Image Successfully Uploaded") 
                        setNotiStatus(true)
                        setNotiError(false)
                        setTimeout(() =>{
                            setNotiStatus(false);
                            setNotiError(false)
                        },4000)
                        setTimeout(() => {
                            setSelectedImagesList(prevList => [...prevList, url]);
                        },4000)
                    }
                    }).catch(() => {
                        setNotiContent("Something Went Wrong Please Try again Later") 
                        setNotiStatus(true)
                        setNotiError(true)
                        setTimeout(() =>{
                            setNotiStatus(false);
                            setNotiError(false)
                        },4000)
                    })
            }
        )
    }

    return (
        <div className='vehical-add-form-container' >
            <div className='vehicale-add-form-all-inputs'>
                <div onClick={() => handleClicked("Country")} className={`custom-selector ${clicked === "Country" && 'clicked'}`} >
                    <label>Country</label>
                    <div className='selected-option' >
                        <p className={`${clicked === "Country"  && 'svg-clicked'}`} ><span>{selectedCountry}</span><BiSolidChevronDown/></p>
                    </div>
                    <div  className={`options overflow-options ${clicked === "Country" && 'option-opened'}`}>
                        <div className='option-inner-container' >
                            {
                                country.map((eachCountry) =>  (
                                    <div onClick={() =>  handleSelectedContry(eachCountry)} className={`each-option ${selectedCountry   === eachCountry && 'selected'}`} >{eachCountry}</div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div onClick={() => handleClicked("Brand")} className={`custom-selector ${clicked === "Brand" && 'clicked'}`} >
                        <label>Brand</label>
                        <div className='selected-option' >
                            <p className={`${clicked === "Brand"  && 'svg-clicked'}`} ><span>{selectedBrand}</span><BiSolidChevronDown/></p>
                        </div>
                        <div  className={`options ${Brand[selectedCountry].length > 6 &&  'overflow-options'} ${clicked === "Brand" && 'option-opened'}`}>
                            <div className='option-inner-container' >
                                {
                                    Brand[selectedCountry].map((specificBarnd) => (
                                        <div onClick={() => handleSelectedBrand(specificBarnd)} className={`each-option ${selectedBrand  === specificBarnd && 'selected'}`} >{specificBarnd}</div>
                                    ))
                                }
                                
                            </div>
                        </div>
                </div>
                <div  className={`custom-selector ${clicked === "Model" && 'clicked'}`} >
                    <label>Model</label>
                    <div className='selected-option' onClick={() => handleClicked("Model")} >
                        <p className={`${clicked === "Model"  && 'svg-clicked'}`} ><span>{selectModel }</span><BiSolidChevronDown/></p>
                    </div>
                    <div  className={`options  ${fillterModel.length > 6 &&  'overflow-options'} ${clicked === "Model" && 'option-opened'}`}>
                        <div className='option-inner-container' >
                            {
                                Car_model[selectedBrand].length > 10 && (
                                    <div className='search-bar-model' >
                                            <input onChange={(e) => handleFillterModel(e)} type='text' placeholder='Search Model'/>
                                            <BsSearch />
                                    </div>
                                )
                            }

                            {
                                fillterModel.length === 0 && (
                                    <p className='no-results-found' >NO RESULTS FOUND</p>
                                )
                            }
                                
                            {
                                fillterModel.map((specificModel) => (
                                    <div onClick={() => handleSelectModel(specificModel)} className={`each-option ${selectModel   === specificModel && 'selected'}`} >{specificModel}</div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div onClick={() => handleClicked("Milieage")} className={`custom-selector ${clicked === "Milieage" && 'clicked'}`} >
                        <label>Km</label>
                        <div className='selected-option' >
                            <p className={`${clicked === "Milieage"  && 'svg-clicked'}`} ><span>{selectMilieage  }</span><BiSolidChevronDown/></p>
                        </div>
                        <div  className={`options overflow-options ${clicked === "Milieage" && 'option-opened'}`}>
                            <div className='option-inner-container' >
                                <div onClick={() => handleSelectMilieage("All Km")} className={`each-option ${selectMilieage    === "All Km" && 'selected'}`} >All Km</div>
                                <div onClick={() => handleSelectMilieage("1,000 Km")} className={`each-option ${selectMilieage    === "1,000 Km" && 'selected'}`} >1,000 Km</div>
                                <div onClick={() => handleSelectMilieage("5,000 Km")} className={`each-option ${selectMilieage    === "5,000 Km" && 'selected'}`} >5,000 Km</div>
                                <div onClick={() => handleSelectMilieage("10,000 Km")} className={`each-option ${selectMilieage    === "10,000 Km" && 'selected'}`} >10,000 Km</div>
                                <div onClick={() => handleSelectMilieage("20,000 Km")} className={`each-option ${selectMilieage    === "20,000 Km" && 'selected'}`} >20,000 Km</div>
                                <div onClick={() => handleSelectMilieage("30,000 Km")} className={`each-option ${selectMilieage    === "30,000 Kms" && 'selected'}`} >30,000 Km</div>
                                <div onClick={() => handleSelectMilieage("40,000 Km")} className={`each-option ${selectMilieage    === "40,000 Km" && 'selected'}`} >40,000 Km</div>
                                <div onClick={() => handleSelectMilieage("50,000 Km")} className={`each-option ${selectMilieage    === "50,000 Km" && 'selected'}`} >50,000 Km</div>
                                <div onClick={() => handleSelectMilieage("60,000 Km")} className={`each-option ${selectMilieage    === "60,000 Km" && 'selected'}`} >60,000 Km</div>
                                <div onClick={() => handleSelectMilieage("70,000 Km")} className={`each-option ${selectMilieage    === "70,000 Km" && 'selected'}`} >70,000 Km</div>
                                <div onClick={() => handleSelectMilieage("80,000 Km")} className={`each-option ${selectMilieage    === "80,000 Km" && 'selected'}`} >80,000 Km</div>
                                <div onClick={() => handleSelectMilieage("90,000 Km")} className={`each-option ${selectMilieage    === "90,000 Km" && 'selected'}`} >90,000 Km</div>
                                <div onClick={() => handleSelectMilieage("100,000 Km")} className={`each-option ${selectMilieage    === "100,000 Km" && 'selected'}`} >100,000 Km</div>
                                <div onClick={() => handleSelectMilieage("110,000 Km")} className={`each-option ${selectMilieage    === "110,000 Km" && 'selected'}`} >110,000 Km</div>
                                <div onClick={() => handleSelectMilieage("120,000 Km")} className={`each-option ${selectMilieage    === "120,000 Km" && 'selected'}`} >120,000 Km</div>
                                <div onClick={() => handleSelectMilieage("130,000 Km")} className={`each-option ${selectMilieage    === "130,000 Km" && 'selected'}`} >130,000 Km</div>
                                <div onClick={() => handleSelectMilieage("140,000 Km")} className={`each-option ${selectMilieage    === "140,000 Km" && 'selected'}`} >140,000 Km</div>
                                <div onClick={() => handleSelectMilieage("150,000 Km")} className={`each-option ${selectMilieage    === "150,000 Km" && 'selected'}`} >150,000 Km</div>
                                <div onClick={() => handleSelectMilieage("160,000 Km")} className={`each-option ${selectMilieage    === "160,000 Km" && 'selected'}`} >160,000 Km</div>
                                <div onClick={() => handleSelectMilieage("170,000 Km")} className={`each-option ${selectMilieage    === "170,000 Km" && 'selected'}`} >170,000 Km</div>
                                <div onClick={() => handleSelectMilieage("180,000 Km")} className={`each-option ${selectMilieage    === "180,000 Km" && 'selected'}`} >180,000 Km</div>
                                <div onClick={() => handleSelectMilieage("190,000 Km")} className={`each-option ${selectMilieage    === "190,000 Km" && 'selected'}`} >190,000 Km</div>
                                <div onClick={() => handleSelectMilieage("200,000 Km")} className={`each-option ${selectMilieage    === "200,000 Km" && 'selected'}`} >200,000 Km</div>
                            </div>
                        </div>
                </div>
                <div onClick={() => handleClicked("Year")} className={`custom-selector ${clicked === "Year" && 'clicked'}`} >
                        <label>Year</label>
                        <div className='year-input'  >
                            <input value={selectyear} type='text' placeholder='Enter Year' onChange={(e) => handleSelectYear(e)} />
                            <BsCalendarMinus />
                        </div>
                </div>
                    
                <div onClick={() => handleClicked("Colur")} className={`custom-selector ${clicked === "Colur" && 'clicked'}`} >
                        <label>Color</label>
                        <div className='selected-option' >
                            <p className={`${clicked === "Colur"  && 'svg-clicked'}`} ><span>{selectColur}</span><BiSolidChevronDown/></p>
                        </div>
                        <div  className={`options overflow-options ${clicked === "Colur" && 'option-opened'}`}>
                            <div className='option-inner-container' >
                                <div onClick={() => handleSelectColour("Pearl")} className={`each-option each-option-color ${selectColur    === "Pearl" && 'selected'}`} ><div className='color-each' style={{background:'#EAE0C8'}} ></div><span>Pearl</span></div>
                                <div onClick={() => handleSelectColour("White")} className={`each-option each-option-color ${selectColur    === "White" && 'selected'}`} ><div className='color-each' style={{background:'#F6F6F6'}} ></div><span>White</span></div>
                                <div onClick={() => handleSelectColour("Yellow")} className={`each-option each-option-color ${selectColur    === "Yellow" && 'selected'}`} ><div className='color-each' style={{background:'yellow'}} ></div><span>Yellow</span></div>
                                <div onClick={() => handleSelectColour("Orange")} className={`each-option each-option-color ${selectColur    === "Orange" && 'selected'}`} ><div className='color-each' style={{background:'orange'}} ></div><span>Orange</span></div>
                                <div onClick={() => handleSelectColour("Wine")} className={`each-option each-option-color ${selectColur    === "Wine" && 'selected'}`} ><div className='color-each' style={{background:'#722F37'}} ></div><span>Wine</span></div>
                                <div onClick={() => handleSelectColour("Pink")} className={`each-option each-option-color ${selectColur    === "Pink" && 'selected'}`} ><div className='color-each' style={{background:'pink'}} ></div><span>Pink</span></div>
                                <div onClick={() => handleSelectColour("Purple")} className={`each-option each-option-color ${selectColur    === "Purple" && 'selected'}`} ><div className='color-each' style={{background:'purple'}} ></div><span>Purple</span></div>
                                <div onClick={() => handleSelectColour("Blue")} className={`each-option each-option-color ${selectColur    === "Blue" && 'selected'}`} ><div className='color-each' style={{background:'blue'}} ></div><span>Blue</span></div>
                                <div onClick={() => handleSelectColour("Brown")} className={`each-option each-option-color ${selectColur    === "Brown" && 'selected'}`} ><div className='color-each' style={{background:'brown'}} ></div><span>Brown</span></div>
                                <div onClick={() => handleSelectColour("Balge")} className={`each-option each-option-color ${selectColur    === "Balge" && 'selected'}`} ><div className='color-each' style={{background:'#f5f5dc'}} ></div><span>Balge</span></div>
                                <div onClick={() => handleSelectColour("Gold")} className={`each-option each-option-color ${selectColur    === "Gold" && 'selected'}`} ><div className='color-each' style={{background:'gold'}} ></div><span>Gold</span></div>
                                <div onClick={() => handleSelectColour("Silver")} className={`each-option each-option-color ${selectColur    === "Silver" && 'selected'}`} ><div className='color-each' style={{background:'silver'}} ></div><span>Silver</span></div>
                                <div onClick={() => handleSelectColour("Gray")} className={`each-option each-option-color ${selectColur    === "Gray" && 'selected'}`} ><div className='color-each' style={{background:'gray'}} ></div><span>Gray</span></div>
                                <div onClick={() => handleSelectColour("Black")} className={`each-option each-option-color ${selectColur    === "Black" && 'selected'}`} ><div className='color-each' style={{background:'black'}} ></div><span>Black</span></div>
                                <div onClick={() => handleSelectColour("Other")} className={`each-option each-option-color ${selectColur    === "Other" && 'selected'}`} ><div className='color-each' style={{background:'#CBDCCB'}} ></div><span>Other</span></div>
                            </div>
                        </div>
                </div>
                    
                <div onClick={() => handleClicked("Price")} className={`custom-selector ${clicked === "Price" && 'clicked'}`} >
                        <label>Price</label>
                        <div className='year-input Price-input'  >
                            <input  value={selectPrice} inputMode='numeric' type='number' placeholder='Enter Price' onChange={(e) => handleSelectPrice(e)} />
                            <BsCurrencyYen style={{fontSize:'20px'}} />
                        </div>
                </div>
                    

                <div onClick={() => handleClicked("Scores")} className={`custom-selector ${clicked === "Scores" && 'clicked'}`} >
                        <label>Scores</label>
                        <div className='selected-option' >
                            <p className={`${clicked === "Scores"  && 'svg-clicked'}`} ><span>{selectScore}</span><BiSolidChevronDown/></p>
                        </div>
                        <div  className={`options  ${clicked === "Scores" && 'option-opened'}`}>
                            <div className='option-inner-container' >
                                <div onClick={() => handleSelectScore("All Score")} className={`each-option ${selectMilieage    === "All Score" && 'selected'}`} >All Score</div>
                                <div onClick={() => handleSelectScore("3")} className={`each-option ${selectMilieage    === "3" && 'selected'}`} >3</div>
                                <div onClick={() => handleSelectScore("3.5")} className={`each-option ${selectMilieage    === "3.5" && 'selected'}`} >3.5</div>
                                <div onClick={() => handleSelectScore("4")} className={`each-option ${selectMilieage    === "4" && 'selected'}`} >4</div>
                                <div onClick={() => handleSelectScore("4.5")} className={`each-option ${selectMilieage    === "4.5" && 'selected'}`} >4.5</div>
                                <div onClick={() => handleSelectScore("s")} className={`each-option ${selectMilieage    === "s" && 'selected'}`} >s</div>
                            </div>
                        </div>
                </div>
            </div>
            <div className='photo-upload-container' >
                <label>Upload Images</label>
                <div  className='photo-upload-container-main'>
                    <input type='file' hidden ref={photoRef} onChange={(e) => handleImageUpload(e)} />
                    {
                        !imageUploadingStatus  ? (
                            <div onClick={handleFileOpen} className='photo-ippload-container'>
                                 <p><BsImages /><span>Upload Vehicale Images</span></p>
                            </div> 
                        ) 
                        : 
                        (
                            <div className='photo-uploading-container' >
                                    <TailSpin
                                    height="40"
                                    width="40"
                                    color="#757F95"
                                    ariaLabel="tail-spin-loading"
                                    radius="1"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                                <p>Uploading Image</p>
                            </div>
                        )
                    }
                    
                </div>
            </div>
            
            {
                selectedImagesList.length !== 0 && (
                    <div className='uploaded-image-container' >
                        {
                            selectedImagesList.map((image , index) => {
                                console.log(index)
                                return(
                                    <div key={index} className='each-image-uploaded' >
                                        <div onClick={() => removeImage(image)} className='svg-uploade-image' >
                                            <MdDeleteOutline /> 
                                        </div>
                                        <img src={image} alt='uploaded-img' />
                                    </div>
                                )
                            })
                        }
                    </div>
                    
                )
            }
            
            <button onClick={handleUploadVehicale} className='theme-btn request-btn'><FiUpload /><span>Add Vehicale</span></button>
        </div>
    )
}

export default VehicaleAddForm