import React from 'react'
import MessengerCustomerChat from 'react-messenger-customer-chat';

function Messanger() {
  return (
    <MessengerCustomerChat
        pageId="128212290364471"
        appId="191882803904380"
        classNamae="messanger"
    />
  )
}

export default Messanger