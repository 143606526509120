import React, { useState  , useRef} from 'react'
import './Contact.css'
import {MdLocationOn  ,MdOutlineMail } from 'react-icons/md'
import {BsPhone } from 'react-icons/bs'
import {AiOutlineClockCircle } from 'react-icons/ai'
import {FaFacebookF , FaWhatsapp} from 'react-icons/fa'
import {TbMailUp} from 'react-icons/tb'
import map from '../../../assets/Basic-Needs/wrapper-section-01.png'
import { useNykContext } from '../../../Context/NykContaxtProvider'
import emailjs from '@emailjs/browser';

function Contact() {
  const [name , setName] = useState("")  
  const [email , setEmail] = useState("")  
  const [phone , setPhone] = useState("")  
  const [message , setMessage] = useState("")  

  const form = useRef()

  const {
    setNotiStatus, 
    setNotiError,
    setNotiContent,
  } = useNykContext()

  const handleContactForm = (e) => {
        e.preventDefault()
        if(name && email && phone && message){

            emailjs.sendForm('service_7mnmhvh', 'template_zbnpul5', form.current, 'YdGJmTEEyMlaSQgNJ')
            .then((result) => {
                if(result.text === "OK"){       
                    setNotiContent("Email Sent Successfully")     
                    setNotiStatus(true);
                    setNotiError(true)
                    setTimeout(() =>{
                          setNotiStatus(false);
                          setNotiError(false)
                    },4000)
                    setEmail("")
                    setMessage("")
                    setName("")
                    setPhone("")
                }else{
                    setNotiContent("Email not sent")
                    setNotiStatus(true);
                    setNotiError(false)
                }
            }, (error) => {
                console.log(error.text);
                setNotiStatus(true);
                setNotiError(false)
                setTimeout(() =>{
                      setNotiStatus(false);
                },4000)
            });

        }else{
            setNotiContent("Please Fill All The Feild")
            setNotiStatus(true);
            setNotiError(false)
            setTimeout(() =>{
                  setNotiStatus(false);
            },4000)
        }
  }

  return (
    <div className='conatct-container' id="contact" >
        <img  src={map} alt='map'/>
        <div className='inner-container-contact' >
                <div className='left-contact-container'  data-aos="fade-right" data-aos-duration="800" >
                    <div className='left-contact-inner-container' >
                        
                        <h2>Contact Info</h2>
                        <p>NYK Trading has a strong and committed sales staff with many years of experience satisfying our customers’ needs.</p>
                       
                        <div className='contact-details' >
                            <MdLocationOn   />
                            <a className='contnet'   href="https://goo.gl/maps/BqH4xsxWAXWmYF8x5"  target="_blank" rel="noreferrer"  >
                                <h3>Office Address:</h3>
                                <p>243-0303 KANAGAWA KEN AIKO GUN AIKAWA</p>
                                <p>MACHI NAKATSU 3501-1 GRAND FAMILIYA 202 </p>
                            </a>
                        </div>
                       
                        <div className='contact-details' >
                            <BsPhone/>
                            <a href="tel:+81462817659"  target="_blank" rel="noreferrer"  className='contnet' >
                                <h3>Call Center</h3>
                                <p>+8146-281-7659</p>
                            </a>
                        </div>
                       
                        <div className='contact-details' >
                            <MdOutlineMail />
                            <a className='contnet' href='mailto:info@nyktradingjp.com'  target="_blank" rel="noreferrer" >
                                <h3>Email</h3>
                                <p>info@nyktradingjp.com</p>
                            </a>
                        </div>
                       
                        <div className='contact-details' >
                            <AiOutlineClockCircle />
                            <div className='contnet'>
                                <h3>Operating Hours:</h3>
                                <p>Mon - Sat (9am to 6 pm)</p>
                            </div>
                        </div>
                       
                        <div className='contact-social' >
                            <a href="https://www.facebook.com/profile.php?id=61550480031077"  target="_blank" rel="noreferrer" className='each-social' >
                                <FaFacebookF />
                            </a>
                            <a href="https://wa.me/+818071182525"  target="_blank" rel="noreferrer" className='each-social' >
                                <FaWhatsapp />
                            </a>
                        </div>

                    </div>
                </div>
                <div className='right-contact-container' data-aos="fade-left" data-aos-duration="800" >

                   <div className='right-contact-container-head' >
                        <h2>Get in touch</h2>
                        <div className='heading-divider'></div>
                        <p>Feel free to browse our massive inventory online, set up a test drive with a sales associate, or inquire about financing!</p>
                   </div>

                  <form onSubmit={(e) => handleContactForm(e)} ref={form} >
                      <div className='custom-input-feild' >
                        <input type='text' placeholder='Your Name' name='name' value={name} onChange={(e) => setName(e.target.value)} />
                      </div>
                      <div className='email-phone'>
                          <div className='custom-input-feild' >
                            <input type='email' placeholder='Your Email' inputMode='email' name='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                          </div>                        
                          <div className='custom-input-feild' >
                            <input type='text' placeholder='Your Phone' inputMode='numeric' name='phone' value={phone} onChange={(e) => setPhone(e.target.value)} />
                          </div>                        
                      </div>
                      <div className='contact-message custom-input-feild' >
                          <textarea cols="72" rows="11" placeholder='Your Mesaage' name='message' value={message}  onChange={(e) => setMessage(e.target.value)}/>
                      </div>

                      <button className='theme-btn request-btn'><TbMailUp /><span>Send Message</span></button>
                  </form>

                  
                </div>
        </div>
        <div className='location-content'data-aos="fade-up" data-aos-duration="800" >
                <iframe title='location' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3247.8447485093325!2d139.3367846753371!3d35.508116939465005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6019018ea2a4c397%3A0xea981cdf38c7599!2sNYK%20TRADING%20COMPANY%20LIMITED!5e0!3m2!1sen!2sjp!4v1692874277100!5m2!1sen!2sjp" width="100%" height="100%" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
  )
}

export default Contact