import React from 'react'
import './ClientRequest.css'
import { TbBrandToyota, TbRoad, TbWorldCheck } from 'react-icons/tb'
import { BiSolidCar, BiTrashAlt } from 'react-icons/bi'
import { BsCalendarMinus } from 'react-icons/bs'
import { MdCreditScore, MdOutlineColorLens } from 'react-icons/md'
import { FaMoneyBillAlt } from 'react-icons/fa'
import { RxCross2 } from 'react-icons/rx'
import { useNykContext } from '../../../../../Context/NykContaxtProvider'
import {PiPhoneCallBold} from 'react-icons/pi'

function ClientRequest() {
  const {clinetRequestBoxStatus , setClinetRequestBoxStatus , clinetRequestBox  , setDeleteConfomrationStatus} = useNykContext()

  const handleClientRequest = () => {
    setClinetRequestBoxStatus(false)
  }

  const handleDelete = () => {
    setDeleteConfomrationStatus(true)
    setClinetRequestBoxStatus(false)
  }

  return (
    <div className={`clinet-request-container ${clinetRequestBoxStatus && 'clinet-request-container-open'}`} >
       <div onClick={handleClientRequest} className='cancel-viewd-vehicale' >
                <RxCross2 />
        </div>
        <div className='clinet-request-box' >
            <div className='clinet-request-box-inner' >
                 <h3>Request From - <span> {clinetRequestBox?.Name}</span></h3>

                 <div className='mr-options mr-optins-request-box' >
                    <div className='mr-eash-option'>
                        <TbWorldCheck />
                        <span>{clinetRequestBox?.Country}</span>
                    </div>
                    <div className='mr-eash-option'>
                        <TbBrandToyota />
                        <span>{clinetRequestBox?.Brand}</span>
                    </div>
                    <div className='mr-eash-option'>
                        <BiSolidCar />
                        <span>{clinetRequestBox?.Model}</span>
                    </div>
                    <div className='mr-eash-option'>
                        <TbRoad />
                        <span>{clinetRequestBox?.Mileage}</span>
                    </div>
                    <div className='mr-eash-option'>
                        <BsCalendarMinus />
                        <span>{clinetRequestBox?.Year}</span>
                    </div>
                    <div className='mr-eash-option'>
                        <MdOutlineColorLens />
                        <span>{clinetRequestBox?.Color}</span>
                    </div>
                    <div className='mr-eash-option'>
                        <FaMoneyBillAlt/>
                        <span>{clinetRequestBox?.Mileage}</span>
                    </div>
                    <div className='mr-eash-option'>
                        <MdCreditScore />
                        <span>{clinetRequestBox?.Score}</span>
                    </div>
                </div>

                <h3 className='persnal-details-head'>Perosnal Details : </h3>
                <div className='request-personal-details' >
                    <h4><span>Name </span> - <span>{clinetRequestBox?.Name}</span></h4>
                    <h4><span>Email </span> - <a href={`mailto:${clinetRequestBox?.Email}`}  target="_blank" rel="noreferrer" >{clinetRequestBox?.Email}</a></h4>
                    <h4><span>Phone </span> - <a href={`tel:+${clinetRequestBox?.Phone}`}  target="_blank" rel="noreferrer"  >+{clinetRequestBox?.Phone}</a></h4>
                </div>
                
                <div className='button-continaer-viewd-vehicale' >
                    <button onClick={handleDelete } className='theme-btn viewd-btn Delete-btn'><BiTrashAlt /><span>Delete Request</span></button>
                    <button  className='theme-btn viewd-btn Update-btn'><PiPhoneCallBold /><a href= {`https://wa.me/+${clinetRequestBox?.Phone}?text= Hey ${clinetRequestBox?.Name} ! May I know, Are You Request ${clinetRequestBox?.Brand} - ${clinetRequestBox?.Model} Car in our www.nyktrdaingjp.com website ?`} target="_blank" rel="noreferrer" ><span>Contact</span><span>{clinetRequestBox?.Name}</span></a></button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ClientRequest