import React  from 'react'
import './VehicaleRequest.css'
import {motion} from 'framer-motion'
import SideBar from '../components/SideBar/SideBar';
import Nav from '../components/Nav/Nav';
import { useNykContext } from '../../../Context/NykContaxtProvider';
import PopupBlack from '../components/PopupBlack/PopupBlack';
import {BiSolidAddToQueue} from 'react-icons/bi'
import Notification from '../../HomePage/Notification/Notification';
import ShowAllRequestedvehicale from './components/ShowAllRequestedvehicale/ShowAllRequestedvehicale';
import ClientRequest from './components/ClientRequest/ClientRequest';
import ClinetDelete from './components/ClinetDelete/ClinetDelete';

function VehicaleRequest() {
  const {adSideBar  ,  setPopupStatus , adSideBarStatusMobile} = useNykContext()


  const handleSidebar = () => {
    setPopupStatus(false)
    adSideBarStatusMobile(false)
  }

  return (
        <motion.div animate={{opacity:1 }} exit={{opacity:0}} initial={{opacity:0 }} transition={{duration:0.5}}>
           <SideBar page='Requests'   dashbord='../Admin/Adminpanel' vehicale='../Admin/Adminpanel/Vehicale' request = './' favourite='../Admin/Adminpanel/Favourite-Vehicale'/>
           <ClientRequest />
           <ClinetDelete />
           <div className={`main-admin-home vehicale-manage ${adSideBar  && 'main-admin-home-full'}`} >
                <Nav />
                <div className='main-admin-innner' >
                    <div className='main-admin-head'>
                      <h1><BiSolidAddToQueue /><span>Requested Vehicale</span></h1>
                    </div>
                    <ShowAllRequestedvehicale />
                </div>
           </div>
           <PopupBlack handleSidebar={handleSidebar} />
           <Notification />
        </motion.div>
  )
}

export default VehicaleRequest