import React from 'react'
import './TopBar.css'
import {LuMail , LuAlarmClock} from 'react-icons/lu'
import {PiPhoneCallBold} from 'react-icons/pi'
import {FaFacebookF} from 'react-icons/fa'

function TopBar() {
  return (
    <div className='top-bar'>
      <div className='top-bar-container conatiner' >
          <div className='contact-details-top-bar' >
              <a href='mailto:info@nyktradingjp.com'  target="_blank" rel="noreferrer" ><LuMail /><span>info@nyktradingjp.com</span></a>
              <a href="tel:+81462817659"  target="_blank" rel="noreferrer" ><PiPhoneCallBold /><span>+8146-281-7659</span></a>
              <a href="https://goo.gl/maps/BqH4xsxWAXWmYF8x5"  target="_blank" rel="noreferrer" ><LuAlarmClock/><span>Mon - Sat (9am to 6 pm)</span></a>
          </div>
          <div className='social-details' >
              <p>Follow Us : </p>
              <a href="https://www.facebook.com/profile.php?id=61550480031077"  target="_blank" rel="noreferrer" ><FaFacebookF/></a>
          </div>
      </div>
    </div>
  )
}

export default TopBar