import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import NykContaxtProvider from "./Context/NykContaxtProvider";
import {BrowserRouter as Router} from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <HelmetProvider>
        <NykContaxtProvider>
            <App />
        </NykContaxtProvider>
      </HelmetProvider>
    </Router>
  </React.StrictMode>
);
