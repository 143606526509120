import React, { useEffect , useState } from 'react'
import Aos from "aos";
import "aos/dist/aos.css";
import {motion} from 'framer-motion'
import Nav from './Nav/Nav';
import TopBar from '../HomePage/Topbar/TopBar';
import PreLoader from '../HomePage/PreLoader/PreLoader';
import Banner from './Banner/Banner';
import Footer from './Footer/Footer';
import NewArrivals from '../HomePage/NewArrivals/NewArrivals';
import Whyus from '../HomePage/WhyUs/Whyus';
import Barands from '../HomePage/Brands/Barands'
import WeAre from '../HomePage/WeAre/WeAre';
import RequestCarBox from '../HomePage/RequestCarBox/RequestCarBox';
import Sidebar from './SideBar/SideBar';
import PopupBlack from '../HomePage/PopupBlack/PopupBlack';
import FixedIcons from '../HomePage/FixedIcons/FixedIcons';
import Messanger from '../HomePage/Messagnger/Messanger';
import Contact from '../HomePage/Contact/Contact';
import {Helmet} from "react-helmet-async";
import ImageSlider from '../HomePage/ImageSlider/ImageSlider';

function ServicePage() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      setTimeout(() => {
        setIsLoading(false);
      }, 4000);
    }, []);
  
    useEffect(() => {
      Aos.init({});
    }, []); 
  
    return (
      <>
        {
          isLoading ? 
          <>
              <PreLoader />
              <Heading />
          </>
          :
          <motion.div animate={{opacity:1 }} exit={{opacity:0}} initial={{opacity:0 }} transition={{duration:0.5}}>
                <Heading />
                <TopBar />
                <Nav />
                <Banner />
                <WeAre />
                <NewArrivals />
                <Whyus />
                <Barands />
                <PopupBlack />
                <Contact />
                <FixedIcons />
                <Messanger />
                <Sidebar />
                <Footer />
                <ImageSlider />
                <RequestCarBox />
          </motion.div>
        }
      </>
    )

}

function Heading(){
  return(
    <Helmet>
        <meta charSet="utf-8" />
        <title>NYK Trading Japan - Our Services</title>
        <meta name="description" content="Discover Comprehensive Vehicle Services for Buying and Selling in Japan. Partner with NYK Trading for Genuine, High-Quality Options." />
    </Helmet>
  )
}


export default ServicePage