import React  from 'react'
import './ShowCaseAllVehicale.css'
import { DataGrid } from '@mui/x-data-grid'
import {AiFillEye} from 'react-icons/ai'
import { useNykContext } from '../../../../../Context/NykContaxtProvider'

const columns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'Image', headerName: 'Image', flex: 1, heigh:120,minWidth: 100,
      sortable : false,
      renderCell : (params) => {

        return (
            <div  className='image-cell data-table-cell' >
                <img src={params.row.Image} alt='cell-img' />
            </div>
        )
      }
    },
    { field: 'Country', headerName: 'Country', flex: 1 ,minWidth: 100, },
    { field: 'Brand', headerName: ' Brand',flex: 1 ,  minWidth: 100, },
    { field: 'Model', headerName: ' Model',flex: 1 ,  minWidth: 100, },
    { field: 'Color', headerName: 'Color', flex: 1  ,   minWidth: 100,},
    { field: 'Year', headerName: 'Year', flex: 1 ,    minWidth: 100,  },
    { field: 'Price', headerName: 'Price', flex: 1 ,    minWidth: 100,  },
  ];


  

function ShowCaseAllVehicale() {
  const {fillterdVehicales , setViewdVehicaleStatus ,  setViewdvehicale  , allVehicales} = useNykContext()

  const actionColumn = [
    {
        feild: 'View', 
        headerName: 'View', 
        flex: 1,
        sortable : false,
        minWidth: 100,
        renderCell : (params) => {
            const handleView = () => {
              const fillterdVehicale = allVehicales.filter((item) => item.id === params.row.id)
              setViewdvehicale(fillterdVehicale[0])
              setViewdVehicaleStatus(true)
            }

            return(
                <div className='button-cell' onClick={handleView} >
                         <button className='cell-buttton-view' ><AiFillEye /><span>View</span></button>
                </div>
            )
        }
    }
  ]

  
  return (
    <div className='show-case-vehicle-container'>
       <div className='show-case-vehicle-container-inner'>
          <h3>Available Vehicales</h3>
          <div className='data-table'>
                <DataGrid
                    rows={fillterdVehicales}
                    rowHeight={85}
                    columns={columns.concat(actionColumn)}
                    initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                    }}
                    pageSizeOptions={[5, 10]}
                    columnVisibilityModel={{
                        id: false,
                    }}
                />
             </div>
        </div>
    </div>
  )
}

export default ShowCaseAllVehicale