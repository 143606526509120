import React from 'react'
import './NewArrivals.css'
import {FaCarAlt} from 'react-icons/fa'
import { PiRoadHorizon } from 'react-icons/pi'
import {BsHeadset} from 'react-icons/bs'
import {HiOutlineArrowNarrowRight} from 'react-icons/hi'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import {PiPhoneCallBold} from 'react-icons/pi'
import {MdOutlineColorLens} from 'react-icons/md'
import {AiOutlineCalendar , AiOutlineStar} from 'react-icons/ai'
import { useNykContext } from '../../../Context/NykContaxtProvider'
import {AiFillEye} from 'react-icons/ai'
import logo from '../../../assets/Logo/nyk-logo-white.png'
import { BiLogoTelegram} from 'react-icons/bi'

function NewArrivals() {
  const {favrtVehicales }   = useNykContext()

  return (
    <div className='full-new-arrivals-container' id="Vehicale" >
        <div className='conatiner' >
            <div data-aos="fade-up" data-aos-duration="800" className='comman-head' >
                <p  className='sub-tittle-comman' ><FaCarAlt /><span>New Arrivals</span></p>
                <h3 className='titlle-coman' >Let's Check Latest <span>Vehicale</span></h3>
                <div className='heading-divider'></div>
            </div>

            <div className='new-arrival-items-container' >
                    {
                        favrtVehicales?.map((eachCar) => (
                            <EachProduct eachCar={eachCar} image={eachCar.Images} Brand={eachCar.Brand} Model={eachCar.Model} Year={eachCar.Year} Price={eachCar.Price} Mileage={eachCar.Mileage} Color={eachCar.Color} Score={eachCar.Score}/>
                        ))
                    }
            </div>

            <div className="sub-contact"  data-aos="fade-up"  data-aos-duration="800" >
                <div className='innner-sc-containe' >
                    <div className='left-sc' >
                        <h2>Get Your Dream Car It's Simple And Affordable</h2>
                        <p>Realize your dream car easily and affordably with NYK Trading. Quality imports and a passion for excellence define our service.</p>
                    </div>
                    <div className='right-sc' >
                        <h2><a href="tel:+81462817659"  target="_blank" rel="noreferrer" ><BsHeadset /><span>+8146-281-7659 </span></a></h2>
                        <button className='theme-btn-2 theme-btn-3' ><AnchorLink href="#request" > <span>Request Dream Car</span><HiOutlineArrowNarrowRight /></AnchorLink></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

function EachProduct({image  , Brand  , Model  ,Year , Price , Mileage , Color , Score , eachCar}){
    const {setImageSliderStatus , setSliderImageList , setMessageSendStatus ,  setMessageSend } = useNykContext()

    const handleSlideOpen = () => {
        setImageSliderStatus(true)
        setSliderImageList(image)
    }

    const handleMessageBox =() => {
        setMessageSendStatus(true)
        setMessageSend(eachCar)
    }


    return(
        <div className='na-product-container'  data-aos="fade-up" data-aos-duration="800" >
                    <div className='na-product-inner-container' >
                        <div className='na-img' >
                            <img className='copyright-nyk' src={logo} alt='logo'/>
                            <div className='na-image-show' onClick={handleSlideOpen} >
                                <AiFillEye />
                            </div>
                            <img className='vehicale-image' src={image[0]} alt='na01'/> 
                        </div>
                    </div>
                    <div className='items-deatils' >
                        <h4>{Brand} - {Model}</h4>
                        <div className='item-option' >
                            <div><PiRoadHorizon  /><span>{Mileage}</span></div>
                            <div><AiOutlineCalendar /><span>{Year}</span></div>
                            <div><MdOutlineColorLens /><span>{Color}</span></div>
                            <div><AiOutlineStar/><span>{Score}</span></div>
                        </div>
                    </div>
                    <div className='price-na' >
                        <h3>{Price}</h3>
                    </div>
                    <div className='na-button-container' >
                
                        <button  className='theme-btn' ><a href= {`https://wa.me/+818071182525?text= Hey NYK Trading ! May I know, Do you have this ${Brand} - ${Model}  car currently ?`}  target="_blank" rel="noreferrer" ><PiPhoneCallBold /><span>Contact</span></a></button>
                        <button onClick={handleMessageBox}  className='theme-btn na-btn' ><BiLogoTelegram /><span>Request</span></button>
                    </div>
        </div>
    )
}

export default NewArrivals