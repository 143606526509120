import React from 'react'
import './PopupBlack.css'
import { useNykContext } from '../../../Context/NykContaxtProvider'

function PopupBlack() {
  const {popupStatus , setPopupStatus , setSiderbarStatus , setReqMailBox} = useNykContext()

  const handlePopStaus = () => {
    setPopupStatus(false)
    setSiderbarStatus(false)
    setReqMailBox(false)
  }

  return (
    <div onClick={handlePopStaus} className={`Popup ${popupStatus && 'popup-open'}`} >

    </div>
  )
}

export default PopupBlack