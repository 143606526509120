import React from 'react'
import { useNykContext } from '../../../../Context/NykContaxtProvider'

function PopupBlack({handleSidebar}) {
 const {popupStatus} = useNykContext()

  return (
    <div onClick={handleSidebar} className={`Popup ${popupStatus && 'popup-open'}`} >

    </div>
  )
}

export default PopupBlack