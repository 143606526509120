import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
import {getStorage} from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyBKrVbPzPv7-Z0ILQwzUfTboxy4c4eiuDA",
  authDomain: "nyktrading-6e22f.firebaseapp.com",
  projectId: "nyktrading-6e22f",
  storageBucket: "nyktrading-6e22f.appspot.com",
  messagingSenderId: "525648223254",
  appId: "1:525648223254:web:50a37cf1b6a52401a86937"
};


const app = initializeApp(firebaseConfig);

export const auth = getAuth(app)
export const db = getFirestore(app)
export const storage = getStorage(app)