import React from 'react'
import './Notification.css'
import {MdOutlineCancel} from 'react-icons/md'
import {BiCheckCircle} from 'react-icons/bi'
import { useNykContext } from '../../../Context/NykContaxtProvider'

function Notification() {
  const { notiStatus  , notiContent , notiError } = useNykContext()
   
  return (
    <div className={notiStatus ? "noti-baar active" : "noti-baar"}  >
         <div className='noti-inner-bar' >
            <h4>
                 {
                    notiError ? 
                    <MdOutlineCancel />
                    :
                    <BiCheckCircle />
                 }
                 <span>{notiContent}</span>
            </h4>
         </div>  
    </div>
  )
}

export default Notification