import React, { useEffect } from "react";
import HomePage from './pages/HomePage/HomePage'
import AboutPage from './pages/AboutPage/AboutPage'
import ContactPage from './pages/ContactPage/ContactPage'
import ServicePage from './pages/ServicePage/ServicePage'
import RequestPage from './pages/RequestPage/RequestPage'
import LoginPage from './pages/AdminPanel/LoginPage/LoginPage'
import AdminHomePage from './pages/AdminPanel/AdminHomePage/AdminHomePage'
import PrivateRoute from './utils/PrivateRoute'
import {Route , Routes} from 'react-router-dom'
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "./firebase-config";
import { useNykContext } from "./Context/NykContaxtProvider";
import VehicaleManagePage from "./pages/AdminPanel/VehicaleManagePage/VehicaleManagePage";
import VehicaleAddPage from "./pages/AdminPanel/VehicaleAddPage/VehicaleAddPage";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import VehicaleUpdatePage from "./pages/AdminPanel/VehicaleUpdatePage/VehicaleUpdatePage";
import FavrtVehicalePage from "./pages/AdminPanel/FavrtVehicalePage/FavrtVehicalePage";
import VehicaleRequest from "./pages/AdminPanel/VehicaleRequest/VehicaleRequest";


function App() {
  const {setUser ,setIsLoged , isLoged ,setAllVehicales  , setFillterdVehicales , allVehicales , setFavrtVehicales  , setClientRequest} = useNykContext()
  const vehicaleCollection = collection(db , 'vehicales')  
  const requestsCollection = collection(db , 'requests')  
 

  useEffect(() => {
      const unSubscribe = onAuthStateChanged(auth , (user) => {
          if(user){
            setIsLoged(true)
            console.log(user.email)
            setUser(user.email)

          }else{
            setIsLoged(false)
          }
      })

      return unSubscribe
  },[setIsLoged , isLoged])

  useEffect(() => {
    const vehicalQ = query(vehicaleCollection, orderBy("creatDate" , "desc"))
    const requestQ = query(requestsCollection, orderBy("creatDate" , "desc"))

    onSnapshot(vehicalQ , (snaps) => {
        setAllVehicales(snaps.docs.map((doc) => ({...doc.data() , id:doc.id}) ))
    })

    onSnapshot(requestQ , (snaps) => {
        setClientRequest(snaps.docs.map((doc) => ({...doc.data() , id:doc.id}) ))
    })

    
  },[])

  useEffect(() => {
      const filteredArray = allVehicales.map((obj) => {
          return {
              id : obj.id,
              Image : obj.Images[0],
              Country : obj.Country,
              Brand : obj.Brand ,
              Model : obj.Model,
              Color : obj.Color,
              Year : obj.Year ,
              Price : obj.Price,
              ISfavourite  : obj.ISfavourite 
          };
      });
      setFillterdVehicales(filteredArray)

      const fillterdvehicales = allVehicales.filter((vehi) => vehi.ISfavourite === true)
      setFavrtVehicales(fillterdvehicales)
  },[allVehicales])

  return (
    <Routes>
        <Route element={<HomePage />} path="/"  />
        <Route element={<AboutPage />} path="/About"  />
        <Route element={<ContactPage />} path="/Contact"  />
        <Route element={<ServicePage />} path="/Services"  />
        <Route element={<RequestPage />} path="/Request-Vehicale"  />
        <Route element={<LoginPage />} path="/Admin/Adminpanel/Login" />
        <Route element={<PrivateRoute />}>
            <Route element={<AdminHomePage />} path="/Admin/Adminpanel" />
            <Route element={<VehicaleManagePage />} path="/Admin/Adminpanel/Vehicale" />
            <Route element={<VehicaleAddPage />} path="/Admin/Adminpanel/Vehicale/Add-Vehicale" />
            <Route element={<FavrtVehicalePage />} path="/Admin/Adminpanel/Favourite-Vehicale" />
            <Route element={<VehicaleRequest />} path="/Admin/Adminpanel/Requested-Vehicale" />
            <Route element={<VehicaleUpdatePage />} path="/Admin/Adminpanel/Vehicale/Update-Vehicale/:id" />
        </Route>
    </Routes>
  );
}

export default App;
