import React from 'react'
import './PreLoader.css'
import { MutatingDots } from  'react-loader-spinner'

function PreLoader() {
  return (
    <div  className="loader-page" >
            <span className='red-blur' ></span>
            <span className='orange-blur' ></span>
            <div className="loader">
                <MutatingDots 
                  height="100"
                  width="100"
                  color="#EF1721"
                  secondaryColor= '#EF1721'
                  radius='12.5'
                  ariaLabel="mutating-dots-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
              />
            </div>
    </div>
  )
}

export default PreLoader