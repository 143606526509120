import React from 'react'
import './ShowCaseVehicale.css'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {AiFillEye} from 'react-icons/ai'
import { useNykContext } from '../../../../../Context/NykContaxtProvider';


function ShowCaseVehicale() {
  const {fillterdVehicales , setViewdvehicale , setViewdVehicaleStatus , allVehicales} = useNykContext()

  const handleViewVehicale = (id) => {
    const fillterdVehicale = allVehicales.filter((item) => item.id === id)
    setViewdvehicale(fillterdVehicale[0])
    setViewdVehicaleStatus(true)
  }


  return (
    <div className='show-case-vehicle-container'>
      <div className='show-case-vehicle-container-inner'>
          <h3>Recently Added Vehicale</h3>

          <TableContainer component={Paper} className='show-case-vehicle-table'>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" className='table-Cell-head' >Image</TableCell>
                  <TableCell align="center" className='table-Cell-head'>Country</TableCell>
                  <TableCell align="center" className='table-Cell-head'>Brand</TableCell>
                  <TableCell align="center" className='table-Cell-head'>Model</TableCell>
                  <TableCell align="center" className='table-Cell-head'>Color</TableCell>
                  <TableCell align="center" className='table-Cell-head'>Year</TableCell>
                  <TableCell align="center" className='table-Cell-head'>Price</TableCell>
                  <TableCell align="center" className='table-Cell-head'>View</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fillterdVehicales?.slice(0,5).map((row) => (
                  <TableRow key={row.id}>
                      <TableCell align="center" >
                        <div className='image-cell' >
                          <img src={row.Image} alt={row.Country} />
                        </div>
                      </TableCell>
                      <TableCell align="center" className='tableCell' >{row.Country}</TableCell>
                      <TableCell align="center" className='tableCell' >{row.Brand}</TableCell>
                      <TableCell align="center" className='tableCell' >{row.Model}</TableCell>
                      <TableCell align="center" className='tableCell' >{row.Color}</TableCell>
                      <TableCell align="center" className='tableCell' >{row.Year}</TableCell>
                      <TableCell align="center" className='tableCell' >{row.Price}</TableCell>
                      <TableCell align="center" className='tableCell tabelcell-button' >
                          <div className='button-cell' onClick={() => handleViewVehicale(row.id)} >
                                <button className='cell-buttton-view' ><AiFillEye /><span>View</span></button>
                          </div>
                      </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
      </div>
    </div>
  )
}

export default ShowCaseVehicale