import React, { useEffect , useState } from 'react'
import TopBar from './Topbar/TopBar'
import Nav from './Nav/Nav'
import Banner from './Banner/Banner'
import About from './About/About'
import Whyus from './WhyUs/Whyus'
import {motion} from 'framer-motion'
import NewArrivals from './NewArrivals/NewArrivals'
import Team from './Team/Team'
import Barands from './Brands/Barands'
import Request from './Request/Request'
import WeAre from './WeAre/WeAre'
import Contact from './Contact/Contact'
import Footer from './Footer/Footer'
import PopupBlack from './PopupBlack/PopupBlack'
import Sidebar from './SideBar/Sidebar'
import Messanger from './Messagnger/Messanger'
import FixedIcons from './FixedIcons/FixedIcons'
import MailRequest from './MailRequest/MailRequest'
import Notification from './Notification/Notification'
import PreLoader from "./PreLoader/PreLoader";
import Aos from "aos";
import "aos/dist/aos.css";
import ImageSlider from './ImageSlider/ImageSlider'
import RequestCarBox from './RequestCarBox/RequestCarBox'

function HomePage() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }, []);

  useEffect(() => {
    Aos.init({});
  }, []); 

  return (
    <>
      {
        isLoading ? 
        <PreLoader />
        :
        <motion.div animate={{opacity:1 }} exit={{opacity:0}} initial={{opacity:0 }} transition={{duration:0.5}}>
            <PopupBlack />
            <Sidebar />
            <TopBar />
            <Nav />
            <Banner />
            <Request />
            <About about_link='./About' />
            <NewArrivals />
            <Team />
            <Whyus />
            <Barands />
            <WeAre />
            <Contact />
            <Footer />
            <Messanger />
            <FixedIcons />
            <MailRequest />
            <Notification />
            <ImageSlider />
            <RequestCarBox />
        </motion.div>
      }
    </>

  )
}

export default HomePage