import React  from 'react'
import './FavrtVehicalePage.css'
import {motion} from 'framer-motion'
import SideBar from '../components/SideBar/SideBar';
import Nav from '../components/Nav/Nav';
import { useNykContext } from '../../../Context/NykContaxtProvider';
import PopupBlack from '../components/PopupBlack/PopupBlack';
import {AiOutlineHeart} from 'react-icons/ai'
import Notification from '../../HomePage/Notification/Notification';
import ShowFvrtVehicales from './components/ShowFvrtVehicales/ShowFvrtVehicales';
import ShowCaseAllVehicale from './components/ShowCaseAllVehicale/ShowCaseAllVehicale';

function FavrtVehicalePage() {
  const {adSideBar  ,  setPopupStatus , adSideBarStatusMobile} = useNykContext()


  const handleSidebar = () => {
    setPopupStatus(false)
    adSideBarStatusMobile(false)
  }

  return (
        <motion.div animate={{opacity:1 }} exit={{opacity:0}} initial={{opacity:0 }} transition={{duration:0.5}}>
           <SideBar page='Favourities' dashbord='../Admin/Adminpanel' vehicale='../Admin/Adminpanel/Vehicale' request = '../Admin/Adminpanel/Requested-Vehicale' favourite='./'/>
           <Notification />
           <div className={`main-admin-home vehicale-manage ${adSideBar  && 'main-admin-home-full'}`} >
                <Nav />
                <div className='main-admin-innner' >
                    <h1><AiOutlineHeart /><span>Favorite Vehicles</span></h1>
                    <ShowFvrtVehicales />
                    <ShowCaseAllVehicale />
                </div>
           </div>
           <PopupBlack handleSidebar={handleSidebar} />
        </motion.div>
  )
}

export default FavrtVehicalePage