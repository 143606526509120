import React  , {useEffect  , useRef , useState} from 'react'
import weareimg from '../../../assets/Basic-Needs/weare.png'
import './WeAre.css'
import {MdSecurity} from 'react-icons/md'
import Plx from "react-plx";


function WeAre() {
    const [height , setHeight] = useState(0)
    const myRef = useRef()

    useEffect(() => {
        if(myRef){
            setHeight(myRef.current.offsetTop)
        }
    },[])

    const parallaxData = [
        {
            start: height  - 600,
            end:height + 800,
            easing : "ease",
            duration:1,
            properties: [
              {
                startValue : 1000,
                endValue: -200,
                property: "translateY",
              },
              {
                startValue : 0,
                endValue: 1,
                property: "opacity",
              },
            ],
          },
    ];
    

  return (
    <div className='we-are-full-container' ref={myRef} >
        <span className='red-blur' ></span>
        <span className='orange-blur' ></span>

        <div className='we-are-content'  >
            <h1 data-aos="fade-up" data-aos-duration="800" >We Are <span className='red-span' >Ensuring</span> the Best <span className='orange-span' >Customer</span> Experience</h1>

            <div className='main-content' >
                <div className='left-content-we-are' >

                  <LeftSideCard head="Request Your Dream Car" para="Use our website to make your dream car request. We'll respond promptly, offering the best options." />
                  <LeftSideCard head="Valuable Insights" para="NYK Trading provides valuable automotive insights, guiding your choices with industry knowledge for informed decisions."/>
                  <LeftSideCard head="Most Flexible Payment" para="NYK Trading offers adaptable payment options, making your automotive dreams a reality with ease."/>

                </div>
                <Plx className="MyAwesomeParallax" parallaxData={parallaxData}>
                    <div  className='middle-content-we-are' >
                        <img  src={weareimg} alt="weareimg"/> 
                    </div>
                </Plx>
                <div className='right-content-we-are' >
                    <RightSideCard head="Consumer-First Mentality" para="NYK Trading's focus is on you – bridging enthusiasts with Japan's finest vehicles through quality-driven service."/>
                    <RightSideCard head="Non-Stop Innovation" para="NYK Trading thrives on constant innovation, bringing fresh approaches to connect enthusiasts with Japan's automotive excellence." />
                    <RightSideCard head="Seamless Car Procurement" para="Effortlessly acquire your desired vehicle through NYK Trading's seamless procurement process." />
                </div>
            </div>
        </div>

    </div>
  )
}

function LeftSideCard({head , para}){
  return(
      <div className='we-are-card' data-aos="fade-right" data-aos-duration="800">
          <div className='we-are-icon' >
              <MdSecurity />
          </div>
          <div className='we-are-card-inner' >
              <h4>{head}</h4>
              <p>{para}</p>
          </div>
      </div>
  )
}

function RightSideCard({head , para}){
  return(
      <div className='we-are-card  we-are-card-right' data-aos="fade-left" data-aos-duration="800">
          <div className='we-are-icon' >
              <MdSecurity />
          </div>
          <div className='we-are-card-inner' >
              <h4>{head}</h4>
              <p>{para}</p>
          </div>
      </div>
  )
}

export default WeAre