import React , { useEffect, useRef, useState} from 'react'
import './Login.css'
import logo from '../../../assets/Logo/NYK-logo-01.png'
import {GrMail} from 'react-icons/gr'
import {AiFillEye  , AiFillEyeInvisible} from 'react-icons/ai'
import {Link , useNavigate} from 'react-router-dom'
import { useNykContext } from '../../../Context/NykContaxtProvider'
import Notification from '../../HomePage/Notification/Notification'
import {auth} from '../../../firebase-config'
import { signInWithEmailAndPassword } from 'firebase/auth'
import {motion} from 'framer-motion'

function LoginPage() {
  const passwordRef = useRef()
  const navigate = useNavigate()

  const [passwordShow , setPasswordShow] = useState(false)
  const [showRoute  , setShowRoute] = useState(false)
  const [password , setPassword] = useState("")
  const [email , setEmail] = useState("")
  const {setNotiContent , setNotiStatus , setNotiError , isLoged} = useNykContext()

  const handlePasswordShow = () => {
    if(!passwordShow){
      passwordRef.current.type = 'text'
      setPasswordShow(true)
    }else{
      passwordRef.current.type = 'password'
      setPasswordShow(false)
    }
  }

  useEffect(() => {
    setTimeout(() => {
       setShowRoute(true)
    },1200)
  },[])

  useEffect(() => {
      if(isLoged){
          navigate('/Admin/Adminpanel' ) 
      }
  },[isLoged , navigate])

  const signinCheckingForm = async (email , password) => {
    if(email === "" && password ==="" ){
      setNotiContent("Please Fill All The Feild")
      setNotiStatus(true);
      setNotiError(true)
      setTimeout(() =>{
            setNotiStatus(false);
      },4000)
    }else if(email === ""){
      setNotiContent("Email Feild Is Empty")
      setNotiStatus(true);
      setNotiError(true)
      setTimeout(() =>{
            setNotiStatus(false);
      },4000)
    }else if(password === ""){
      setNotiContent("Password Feild Is Empty")
      setNotiStatus(true);
      setNotiError(true)
      setTimeout(() =>{
            setNotiStatus(false);
      },4000)
    }else{
          if(password.length < 6 ){
            setNotiContent("Password should be at least 6 characters")
            setNotiStatus(true);
            setNotiError(true)
            setTimeout(() =>{
                  setNotiStatus(false);
            },4000)
          }else{
              if(!email.includes("@")){
                setNotiContent("Email Not In a Correct Format")
                setNotiStatus(true);
                setNotiError(true)
                setTimeout(() =>{
                      setNotiStatus(false);
                },4000)
              }else{
                try {
                   await signInWithEmailAndPassword(auth , email , password)
                   setEmail("")
                   setPassword("")
                   setNotiContent("Loged Successfully")
                   setNotiStatus(true);
                   setNotiError(false)
                   setTimeout(() =>{
                         setNotiStatus(false);
                   },4000)
                } catch (error) {
                   if(error.message === "Firebase: Error (auth/user-not-found)."){
                       setNotiContent("User Not Found")
                       setNotiStatus(true);
                       setNotiError(true)
                       setTimeout(() =>{
                             setNotiStatus(false);
                       },4000)
                   }else if(error.message === "Firebase: Error (auth/wrong-password)."){
                       setNotiContent("Invalid Password")
                       setNotiStatus(true);
                       setNotiError(true)
                       setTimeout(() =>{
                             setNotiStatus(false);
                       },4000)
                   }
                  
                }
              
              }
          }
     }
  }  

  const handleSignIn = (e) => {
    e.preventDefault()
    signinCheckingForm(email , password)
  }

  return (
    <>
      {
        showRoute && (
          <motion.div animate={{opacity:1 }} exit={{opacity:0}} initial={{opacity:0 }} transition={{duration:0.7}}>
            <Notification />
            <div className='login-page-container' >
                <div className='login-page-image-container' >
                    <div className='lpic-content' >
                        <h3>NYK Trading Co.Ltd - Admin Panel</h3>
                        <h5>Japan Biggest Vehicale Suppliers All Around The World</h5>
                    </div>
                </div>
                <div className='login-page-form-container' >            
                    <span className='orange-blur orange-blur-login ' ></span>
                    <span className='red-blur red-blur-login' ></span>
                    <div className='lpfc-inner-container' >
                        <div className='nav-image' >
                          <img src={logo} alt='logo' />
                        </div>
                        <form onSubmit={(e) => handleSignIn(e)}>
                          <h3>Sign In</h3>
      
                          <div className='all-form-feild' >
                              <div className='lpfc-form-feild' >
                                  <label>Email Address</label>
                                  <div className='lpfc-input' >
                                      <input value={email} type='text' placeholder='Enter Email Address' onChange={(e) => setEmail(e.target.value)} />
                                      <GrMail />
                                  </div>
                              </div>
                              <div className='lpfc-form-feild' >
                                  <label>Password</label>
                                  <div className='lpfc-input' >
                                      <input value={password} type='password' placeholder='Enter Password' ref={passwordRef} onChange={(e) => setPassword(e.target.value)} />
                                      {
                                        passwordShow ? 
                                        (<AiFillEyeInvisible onClick={handlePasswordShow} />)
                                        :
                                        (<AiFillEye onClick={handlePasswordShow} />)
                                      }
                                  </div>
                              </div>
                              <button className='theme-btn mr-request-btn request-btn signin-btn'><span>Sign In</span></button>
                              <p> <span>Not a admin visit ? </span> <Link to='../../'>Home</Link></p>
                          </div>
                        </form>
                    </div>
                </div>
            </div>
          </motion.div>
        )
      }
    </>

  )
}

export default LoginPage