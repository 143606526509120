import React from "react";
import "./SideBar.css";
import logo from "../../../../assets/Logo/NYK-logo-01.png";
import {AiOutlineDashboard  , AiOutlineHeart} from 'react-icons/ai'
import {FaCar , FaTelegramPlane} from 'react-icons/fa'
import { useNykContext } from "../../../../Context/NykContaxtProvider";
import {Link} from 'react-router-dom'
import { RiAdminFill } from "react-icons/ri";

function SideBar({page ,dashbord , vehicale , request , favourite}) {
  const {user , adSideBar , adSideBarMobile , setPopupStatus  , adSideBarStatusMobile } = useNykContext()

  const handleNavigation = () => {
    setPopupStatus(false)
    adSideBarStatusMobile(false)
  }

  return (
    <div className={`admin-sidebar ${adSideBar && 'admin-sidebar-close'} ${adSideBarMobile  && 'admin-sidebar-open'}` }>
        <div className="as-inner-conatiner">

            <div className="sidebar-admin-head">
                <div className="sa-img">
                     <img src={logo} alt="logo" />
                </div>
                <div className="sa-contnet" >
                    <h3>NYK Trading Co.Ltd</h3>
                    <p>info@nyktradingjp.com</p>
                </div>
            </div>
            
            <div className="main-links" >
                    <Link onClick={handleNavigation} className={` ${page === 'Dashbord' && 'active-ap'}`} to={dashbord}>
                        <AiOutlineDashboard />
                        <span>Dashbord</span>
                    </Link>
                    <Link onClick={handleNavigation} className={` ${page === 'Vehicales' && 'active-ap'}`} to={vehicale}> 
                        <FaCar />
                        <span>Vehicales</span>
                    </Link>
                    <Link onClick={handleNavigation} className={` ${page === 'Favourities' && 'active-ap'}`} to={favourite}>
                        <AiOutlineHeart />
                        <span>Favourities</span>
                    </Link>
                    <Link onClick={handleNavigation} className={` ${page === 'Requests' && 'active-ap'}`}  to={request} >
                        <FaTelegramPlane />
                        <span>Requests</span>
                    </Link>
                    {
                        user === "info@nyktradingjp.com" && (
                        <Link>
                            <RiAdminFill />
                            <span>Admins</span>
                        </Link>
                        )
                    }

            </div>

        </div>
    </div>
  );
}

export default SideBar;
