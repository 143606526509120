import React from 'react'
import './Brands.css'
import {AiFillCar} from 'react-icons/ai' 
import brand01 from '../../../assets/Brands/01.webp'
import brand02 from '../../../assets/Brands/02.png'
import brand03 from '../../../assets/Brands/03.png'
import brand04 from '../../../assets/Brands/04.png'
import brand05 from '../../../assets/Brands/05.png'

function Barands() {
  return (
    <div className='full-brand-conatiner brand-container' id="brands"  >
        <div data-aos="fade-up" data-aos-duration="800" className='comman-head' >
            <p  className='sub-tittle-comman' ><AiFillCar /><span>Popular Brands</span></p>
            <h3 className='titlle-coman' >Our Top Quality <span>Brands </span></h3>
            <div className='heading-divider'></div>
        </div>
        <div data-aos="fade-up" data-aos-duration="800" className='wrap-slider' >
            <div className='brands-sub-conainer' >
                    <EachBarnd name="TOYATA" image={brand01}/>
                    <EachBarnd name="BMW" image={brand02}/>
                    <EachBarnd name="FERRARI" image={brand03}/>
                    <EachBarnd name="BENZ" image={brand04}/>
                    <EachBarnd name="HYNDAI" image={brand05}/>
                    <EachBarnd name="TOYATA" image={brand01}/>
                    <EachBarnd name="BMW" image={brand02}/>
                    <EachBarnd name="FERRARI" image={brand03}/>
                    <EachBarnd name="BENZ" image={brand04}/>
                    <EachBarnd name="HYNDAI" image={brand05}/>
                    <EachBarnd name="TOYATA" image={brand01}/>
            </div>
        </div>
    </div>
  )
}

function EachBarnd({name , image}){
    return(
        <div className='each-brand' >
                <img src={image} alt='brand01' />
                <h3>{name}</h3>
        </div>
    )
}

export default Barands