import React from 'react'
import logo from '../../../assets/Logo/NYK-logo-01.png'
import {MdOutlineClose , MdLocationOn} from 'react-icons/md'
import {LuMail } from 'react-icons/lu'
import {BiLogoTelegram} from 'react-icons/bi'
import {PiPhoneCallBold} from 'react-icons/pi'
import {FaFacebookF} from 'react-icons/fa'
import { useNykContext } from '../../../Context/NykContaxtProvider'
import {RiContactsLine} from 'react-icons/ri'
import {HiUserGroup} from 'react-icons/hi'
import {TbSettingsStar} from 'react-icons/tb' 
import {BiHome} from 'react-icons/bi'
import {Link} from 'react-router-dom'

function Sidebar() {
    const {setSiderbarStatus , siderbarStatus , setPopupStatus} = useNykContext()
   
  const handleSideBar = () => {
    setPopupStatus(false)
    setSiderbarStatus(false)
  }  

  return (
    <div className={`sidebar ${siderbarStatus && 'sidebar-open'}`} >
        <div className='sidebar-inner-container' >
            
            <div className='sidebar-head' >
                <img src={logo} alt='logo' />
                <div className='closed-icon' onClick={handleSideBar} >
                    <MdOutlineClose />
                </div>
            </div>

            <div className='side-bar-about' >
                <h3>About Us</h3>
                <p>Quality meets passion at NYK Trading. Expert sourcing, transparent practices, and global partnerships ensure exceptional automotive imports and exports. Join our journey of excellence.</p>
            </div>
            <div className='side-bar-quick-links' >
                <h3>Quick Links</h3>
                <div className='links-container' >
                    <Link onClick={handleSideBar} to='../'><BiHome /><span>Home</span></Link>
                    <Link onClick={handleSideBar} to='./' className='active'  ><HiUserGroup/><span>About</span></Link>
                    <Link onClick={handleSideBar} to='../Contact' ><RiContactsLine /><span>Contact</span></Link>
                    <Link onClick={handleSideBar} to='../Services' ><TbSettingsStar /><span>Services</span></Link>
                    <Link onClick={handleSideBar} to='../Request-Vehicale' ><BiLogoTelegram  /><span>Request</span></Link>
                </div>
            </div>
            
            <div className='side-bar-contact' >
                   <h3>Contact Info</h3>

                   <div className='side-bar-contact-content' >  
                        <div className='side-bar-each-contact' >
                            <h5>Phone</h5>
                            <a href="tel:+81462817659"  target="_blank" rel="noreferrer" ><PiPhoneCallBold /><span>+8146-281-7659</span></a>
                        </div>
                        <div className='side-bar-each-contact' >
                            <h5>Email</h5>
                            <a href="mailto:info@nyktradingjp.com"  target="_blank" rel="noreferrer" ><LuMail /><span>info@nyktradingjp.com</span></a>
                        </div>
                        <div className='side-bar-each-contact' >
                            <h5>Location</h5>
                            <a href="https://goo.gl/maps/BqH4xsxWAXWmYF8x5"  target="_blank" rel="noreferrer" ><MdLocationOn /><span>243-0303 KANAGAWA KEN AIKO GUN  NAKATSU 3501-1</span></a>
                        </div>
                   </div>
            </div>

            <div className='sidebar-social' >
                <h3>Follow Us</h3>

                <div className='sidebar-social-content' >
                    <a  href="https://www.facebook.com/profile.php?id=61550480031077"  target="_blank" rel="noreferrer" className='sidebar-each-social' >
                        <FaFacebookF />
                    </a>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Sidebar