import React from 'react'
import './Team.css'
import {BsMicrosoftTeams} from 'react-icons/bs'
import team01 from '../../../assets/Team/Faisal.jpg'
import team02 from '../../../assets/Team/NAZ.jpg'
import {LuMail} from 'react-icons/lu'
import {PiPhoneCallBold} from 'react-icons/pi'
import {BsWhatsapp} from 'react-icons/bs'

function Team() {
  return (
    <div className='conatiner team-container'  >
        <div data-aos="fade-up" data-aos-duration="800" className='comman-head' >
            <p  className='sub-tittle-comman' ><BsMicrosoftTeams /><span>Team</span></p>
            <h3 className='titlle-coman' >Meet With Our <span>Team</span></h3>
            <div className='heading-divider'></div>
        </div>
        <div className='team-sub-container' >
            <TeamMember  image={team02} name="Mohamed Razick Mohamed Nazreen" role="Managing In Director" job="Administrator & In-Charge" mail="nazrinrazick123@gmail.com"  phone="818047190762"/>
            <TeamMember  image={team01} name="Abdul Cafoor Faizal Mohomed" role="Senior Export Manager" job="Communicate with export- import and the related departments, authorities, clients and vehicle dealers in all relevant territories and countries to ensure a smoother operation." mail="fmohamed3912@gmail.com" phone='818071182525' />
        </div>
    </div>
  )
}

function TeamMember({image , name , role , job , phone , mail}){
    return(
        <div className='each-team' data-aos="fade-up" data-aos-duration="800" >
            <ul>
                <li><a href={`https://wa.me/${phone}`}   target="_blank" rel="noreferrer"  ><BsWhatsapp /></a></li>
                <li><a href={`mailto:${mail}`}  target="_blank" rel="noreferrer"  ><LuMail/></a></li>
                <li><a href={`tel:+${phone}`}  target="_blank" rel="noreferrer"  ><PiPhoneCallBold /></a></li>
            </ul>
            <div className='each-image-container' >
                <img src={image} alt='team01' /> 
            </div>
            <div className='team-details' >
                    <h1>{name}</h1>
                    <h4>{role}</h4>
                    <p>{job}</p>
            </div>
        </div>
    )
}

export default Team