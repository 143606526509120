import React from 'react'
import {FiChevronRight} from 'react-icons/fi'
import {Link} from 'react-router-dom'
import image from '../../../assets/Basic-Needs/Service-Page.jpg'

export default function Banner() {
  return (
    <div className='ab-banner' style={{backgroundImage: `linear-gradient(320deg, rgba(0, 0, 0, 0.7), rgb(0, 0, 0.1)), url('${image}')`}} >
        <p><Link to='../'>Home </Link> <FiChevronRight /> <span> Services</span></p>
    </div>
  )
}
