import React from 'react'
import logo from '../../../assets/Logo/NYK-logo-01.png'
import {PiPhoneCallBold} from 'react-icons/pi'
import {MdLocationOn  ,MdOutlineMail } from 'react-icons/md'
import {FaCaretRight } from 'react-icons/fa'
import {BiLogoTelegram } from 'react-icons/bi'
import {Link} from 'react-router-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'

function Footer() {
  return (
    <div className='footer-full-container ' >
        <div className='footer-inner-conatiner'>
            <div className='conatiner footer-container'>
                <div className='fcol-01' >

                    <img src={logo} alt='logo' />
                    <p>Quality meets passion at NYK Trading. Expert sourcing, transparent practices, and global partnerships ensure exceptional automotive imports and exports. Join our journey of excellence.</p>
                    <div className='footer-col1-content' >
                        <a className='footer-col1-content-each' href="tel:+81462817659"  target="_blank" rel="noreferrer" >
                            <div className='col-01-icon'>
                                <PiPhoneCallBold />
                            </div>
                            <p>+8146-281-7659</p>
                        </a>
                        <a className='footer-col1-content-each'  href="https://goo.gl/maps/BqH4xsxWAXWmYF8x5"  target="_blank" rel="noreferrer">
                            <div className='col-01-icon'>
                                <MdLocationOn  />
                            </div>
                            <p className='location-p' >243-0303 KANAGAWA KEN AIKO GUN  NAKATSU 3501-1</p>
                        </a>
                        <a className='footer-col1-content-each'   href="mailto:info@nyktradingjp.com"  target="_blank" rel="noreferrer" >
                            <div className='col-01-icon'>
                                <MdOutlineMail />
                            </div>
                            <p>info@nyktradingjp.com</p>
                        </a>
                    </div>

                </div>
                <div className='fcol-02 fcol-02-03' >
                    <div className='fcol-02-03-inner' >
                        <h3 className='footer-head' >Quick Links</h3>

                        <div className='fcol-02-links' >
                            <Link to="../About" className='each-social'><FaCaretRight /><span>About Us</span></Link>
                            <Link to="./" className='each-social'><FaCaretRight /><span>Contact Us</span></Link>
                            <Link to="../Services" className='each-social'><FaCaretRight /><span>Our Services</span></Link>
                            <Link to="../Request-Vehicale" className='each-social'><FaCaretRight /><span>Request Vehicale</span></Link>
                        
                        </div>
                    </div>
                </div>
                <div className='fcol-03 fcol-02-03' >
                    <div className='fcol-02-03-inner' >
                        <h3 className='footer-head' >Usefull Links</h3>

                        <div className='fcol-02-links' >
                            <AnchorLink href='#Vehicale' className='each-social'><FaCaretRight /><span>Our Vehicles</span></AnchorLink>
                            <AnchorLink href='#brands' className='each-social'><FaCaretRight /><span>Our Brands</span></AnchorLink>
                            <AnchorLink href='#whynyk' className='each-social'><FaCaretRight /><span>Why NYK</span></AnchorLink>
                        </div>
                    </div>
                </div>
                <div className='fcol-04' >
                    <div className='fcol-04-inner' >
                        <h3 className='footer-head' >Newsletter</h3>
                        <form className='fcol-04-form' >
                            <p>Subscribe Our Newsletter To Get Latest Update And News</p>
                            <div className='subscribe-input' >
                                <input type='email' placeholder='Enter Email'/>
                            </div>
                            <button className='theme-btn footer-btn'><BiLogoTelegram/><span>Subscribe Now</span></button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div className='copyright' >
            <div className='conatiner'>
              <p className='umair' >Designed And Developed By <a href="https://umairlafir-1ae7d.web.app"  target="_blank" rel="noreferrer">Mohamad Lafir Umair</a> </p>
              <p className='design-flix' >© Copyright 2023 All rights reserved By <a href="https://wa.me/+94768383561"  target="_blank" rel="noreferrer" >Design Flix</a></p>
            </div>
        </div>
    </div>
  )
}

export default Footer