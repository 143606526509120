import React  from 'react'
import './About.css'
import aboutImg from '../../../assets/Basic-Needs/about.png'
import {HiUserGroup , HiOutlineArrowNarrowRight } from 'react-icons/hi'
import{BiCheckCircle} from 'react-icons/bi'
import {FaPeopleCarry} from 'react-icons/fa'
import {Link} from 'react-router-dom'

function About({about_link}) {

  return (
    <div className='conatiner about-container' >
        <div className='about-inner-container'>
            <div className='about-left' data-aos="fade-right" data-aos-duration="800" >
                <div className='expeerience' >
                    <div className='service-icon' >
                        <FaPeopleCarry />
                    </div>
                    <p><span>30 Years Of </span><span> Quality Service</span></p>
                </div>
                <img src={aboutImg} alt='about-img' />
            </div>
            <div className='about-right' >
                <div className='about-right-inner-container' >
                    <p data-aos="fade-down" data-aos-duration="800" className='sub-tittle' ><HiUserGroup /><span>About Us</span></p>
                    <h3 data-aos="fade-left" data-aos-duration="800" >World Largest <span>vehicale Dealer</span> Marketplace</h3>
                    <p data-aos="fade-right" data-aos-duration="800" className='descripbtion'>Welcome to NYK Trading  your partner for top quality vehicle imports and exports from Japan. With a focus on transparency and customer satisfaction, we bridge the gap between enthusiasts and Japan's automotive treasures. </p>
                    <div className='Points' >
                        <p data-aos="fade-up" data-aos-duration="800" ><BiCheckCircle /><span> Your Reliable Automotive Partner </span></p>
                        <p data-aos="fade-up" data-aos-duration="800" ><BiCheckCircle /><span> Passion Drives Exceptional Experiences. </span></p>
                        <p data-aos="fade-up" data-aos-duration="800" ><BiCheckCircle /><span> Experts in Automotive Excellence. </span></p>
                    </div> <button data-aos="fade-up" data-aos-duration="800" className='theme-btn' ><Link to={about_link} ><span>Discover More</span><HiOutlineArrowNarrowRight /></Link></button>
                   
                </div>
            </div>
        </div>
    </div>
  )
}

export default About